<div style="position: relative;">
    <!-- <div class="welcome" style="margin-top: 50px; margin-bottom: 30px;" tabindex="0">
        <label class="horizontalline">
        </label>WELCOME<label class="horizontalline"></label>
    </div> -->
    <div class="welcome landing-welcome" tabindex="0">
        <label class="horizontalline"><b class="adafix">.</b></label>
        WELCOME
        <label class="horizontalline"><b class="adafix">.</b></label>
    </div>
    <div align="center" class="mt-3">
        <div style="position: relative; opacity: 1; margin-bottom: 4rem;">
            <img src="assets/livepad-logo-black.svg" alt="Image"  class="landingImg" style="opacity: 0.9;" name="livepad"
                (click)="gotoLogin()" tabindex="0">
        </div>
    </div>
</div>