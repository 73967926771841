<div class="container">
	<div class="content-container">
		<h1 class="heading1-summary">Summary of your Property Tour facilitated by LivePad</h1>
		<div style="text-align: center;position: relative; letter-spacing: -1px; margin: 40px 0 30px 0">
			<p class="properties-visited-heading">
				PROPERTIES VISITED
			</p>
		</div>
		<div class="details-wrapper">
			<ng-container *ngFor="let info of sectionInfo()">
				<div class="details-content">
				{{ info !=='undefined'?info:'' }}
				</div>
			</ng-container>
		</div>
		<div class="tour-summary-details-card" *ngFor="let showing of tour?.showings; let i = index ">
			<app-tour-summary-details-card
				  [property]="showing.property"
				  [client]="getClient()"
				  [showing]="showing"
				  [propertyIndex]="i + 1">
			</app-tour-summary-details-card>
		</div>
		<div style="text-align:center;">
			<button tabindex="0" type="submit" (click)="onOpenModal()">EMAIL TO CLIENT</button>
		</div>
	</div>
	<div class="modal" *ngIf="showTourSummaryModal">
		<div class="modal-content">      
		  <div class="container">
			<h1 style="font-family: 'Dunbar Tall', sans-serif;">TOUR SUMMARY</h1>
			<div class="row justify-content-center">
				<div class="col-md-12"  >
					<mat-form-field class="mt-3">
						<mat-label>Email</mat-label>
						<!-- pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"  -->
						<input type="email" matInput name="email" id="emails"  ngModel #email="ngModel"   required (input)="errorInvalidEmail=false">
					</mat-form-field>
					<br>
				</div>
				<div class="col-sm-12">
					<div *ngIf="email.touched">
						<p *ngIf="email.errors?.required"><small>Email is a required field!</small></p>
						<p *ngIf="!email.errors?.required && errorInvalidEmail"><small>This is not a valid Email!!!</small></p>
					</div>
				</div>
			</div>
			<div class="btn-wrap">
			<button type="submit" class="tour-complete-btn me-2" (click)="onSubmitGenerateLink(email.value)" [disabled]="!email.valid||(!email.errors?.required && errorInvalidEmail)">SEND</button> 
			<button type="reset" class="tour-complete-btn bg-light text-dark" (click)="onCloseModal()">CANCEL</button>
			</div>
		  </div>
		</div>
	</div>
	<app-spinner [show]="showSpinner"></app-spinner>
</div>
	