
<div class="content-container pt-0">
	<mat-list class="tours-list tour-section-header" >
		<h2>Tour Details</h2>
		<div class="tour-list-wrapper">
		<button mat-raised-button color="primary" class="mx-2 px-2 all-btn" [class.active-btn]="sortText=='all'" (click)="dataFilter('all');onFilterChange('all')">All</button>
		<div class="Vdivider"></div>
		<!-- <hr/> -->
		<div class="middle-buttons-wrapper">
			<ng-container *ngFor="let tourSection of filteredToursInSequence;let i=index">
				<div [style]="sectionStyle(tourSection.key)" >
					<button mat-stroked-button color="primary" class="mx-2 custom-button-style" [class.active-btn]="sortText==tourSection.display?true:false" (click)="dataFilter(tourSection.display);onFilterChange($event.target.value)" [tabindex]="0" [value]="tourSection.display">{{ tourSection.display }}</button>	
				</div>
			</ng-container>
		</div>
		<div class="Vdivider"></div>
			<div class="searchbar-button-wrapper tourMargin d-flex justify-content-start">
				<app-search-bar (onFilterChange)="filterChange($event)" class="custom-width" #filterComponent></app-search-bar>	
				<button title="Delete Tours" class="trash-button" (click)="onDeleteTours()" [disabled]="selectedTours().length == 0">
					<img src="assets/trash.svg" alt="Delete" matSuffix [ngClass]="{'disabled': selectedTours().length == 0}" width="20" />
				</button>
			</div>
		</div>
	</mat-list>
<mat-list class="tours-list my-3" *ngFor="let tour of dataFilterValue; let index = index; trackBy: trackByTourId">
	<div class="filter" >
		<mat-accordion multi="false" >
			<mat-expansion-panel [expanded]="addShowingsTourId===tour.id?true:false">
				<!-- <mat-expansion-panel-header> -->
				<mat-expansion-panel-header (click)="onHeaderClick(tour)">
					<mat-panel-title class="tour-panel-center-align">
						<div class="tour-grid">
							
						<div class="column1">
							<div class="first-column">
								<div class="checkbox-heading-wrap"><mat-checkbox class="tour-title tour-checkbox" (change)="onSelectOneTour(tour, $event)" tabindex="0" (click)="$event.stopPropagation();"><b class="adafix">.</b></mat-checkbox>
							  <!-- <img src="assets/avatar.jpg" alt="User Picture" class="tour-user" width="24" tabindex="0"> -->
							  <h4 tabindex="0" >
								{{ tour.name }} 
								<!-- <img src="assets/info-icon.svg" alt="Info Icon" width="24" tabindex="0" [matTooltip]="tour?.description" #tooltip="matTooltip"> -->								
							  </h4>
							</div>
							  <img src="assets/info-icon.svg" alt="Info Icon" width="24" tabindex="0"
           							[matTooltip]="tour?.description !== 'null' ? tour.description : ''"
           							[matTooltipClass]="'custom-tooltip'" #tooltip="matTooltip">						
							</div>
						</div>
						
						<div class="column2" style="display:none;">
							<p tabindex="0">{{ tour.description !== 'null' ? tour.description : '' }}</p>
						</div>
						<div class="Vdivider2"></div>
						<!-- <div class="column2">
							<label class="custom-link">ADD Showing</label>	
							<a routerLink="../properties" [queryParams]="{tour: this.tour.id}" tabindex="0">
							  <i class="bi bi-plus-square plusicon icon-color"></i> 
							</a>							
						</div> -->
						<div class="column2">
							<a routerLink="../properties" [queryParams]="{ tour: this.tour.id }" tabindex="0">
								<label class="custom-link">Add Showing</label>
								<i class="bi bi-plus-square plusicon icon-color"></i>
							</a>
						</div>
						<div class="Vdivider2"></div>
						<!-- <div class="column2">
							<a href="#" tabindex="0" style="display:none;">
							  <label>ADD Client</label>
							  <i class="bi bi-plus-square plusicon icon-color"></i>
							</a>
							<div class="custom-client-wrap" *ngFor="let client of tour.clients">
								<div><img [src]="client?.avatar?.url"></div>
								<div class="custom-client-list">
									<label>{{ client.firstName }} {{ client.lastName }}</label>
								</div>
								<i class="bi bi-plus-square plusicon icon-color"></i>
							</div>
						</div> -->
						<div class="column2">
							<!-- Check if tour.clients has any elements -->
							<!-- <ng-container *ngIf="tour.clients.length > 0; else addClientSection">
								<div class="custom-client-wrap" *ngFor="let client of tour.clients" (click)="onOpenModa2($event, tour)">
									<div><img [src]="client?.avatar?.url" width="30"></div>
									<div class="custom-client-list">
										<label>{{ client.firstName }} {{ client.lastName }}</label>
									</div>
									<i class="bi bi-plus-square plusicon icon-color" (click)="navigateToClients(tour.id)"></i>								
								</div>
							</ng-container> -->
							<ng-container *ngIf="tour.clients.length > 0; else addClientSection">
								<!-- Display details of the first client only -->
								<div class="custom-client-wrap" (click)="onOpenModa2($event, tour)">
									<div *ngIf="tour.clients.length > 1; else singleClientSection">
										<img *ngIf="tour.clients[0]?.avatar?.url; else defaultAvatar" [src]="tour.clients[0]?.avatar?.url" width="30" height="30" alt="Client Picture">
										<img *ngIf="tour.clients[1]?.avatar?.url; else defaultAvatar" [src]="tour.clients[1]?.avatar?.url" width="30" height="30" alt="Client Picture">
									</div>
									<ng-template #singleClientSection>
										<img *ngIf="tour.clients[0]?.avatar?.url; else defaultAvatar" [src]="tour.clients[0]?.avatar?.url" width="30" height="30" alt="Client Picture">
									</ng-template>
									<ng-template #defaultAvatar>
										<img src="./assets/avatar.jpg" width="30" alt="Client Picture">
									</ng-template>
									<div class="custom-client-list">
										<label>{{ tour.clients[0]?.firstName }} {{ tour.clients[0]?.lastName }}</label>
									</div>
									<i class="bi bi-plus-square plusicon icon-color" (click)="navigateToClients(tour.id)"></i>
								</div>
							</ng-container>
						
							<!-- Template for when tour.clients has no elements -->
							<ng-template #addClientSection>
								<a routerLink="../clients" [queryParams]="{tour: this.tour.id}" tabindex="0">
									<label class="custom-link">Add Client</label>
									<i class="bi bi-plus-square plusicon icon-color"></i>
								</a>
							</ng-template>
						</div>
						<div class="Vdivider2"></div>
						<div class="column2 column-date">
							<!-- <div class="tour-date">
							  <input id="matInput" type="date" class="date-picker title-date-picker" (click)="$event.stopPropagation();" (change)="onUpdateTourDate(tour,$event,tour.tourDate)" 
								[value]="tour.tourDate | date:'yyyy-MM-dd' " min="{{ today | date:'yyyy-MM-dd' }}" max="{{ nextYear | date:'yyyy-MM-dd' }}" aria-labelledby="matInput">
							</div> -->
							<div class="tour-date">

								<mat-form-field class="tour-form-field"  >
									<!-- <input  matInput [matDatepicker]="picker" (click)="$event.stopPropagation();" [value]="tour.tourDate | date:'yyyy-MM-dd' " [min]="today | date:'yyyy-MM-dd'" [max]="nextYear | date:'yyyy-MM-dd'" aria-labelledby="matInput"
									(dateChange)="onUpdateTourDate(tour,$event,tour.tourDate)"
									/> -->
									<input  matInput [matDatepicker]="picker" (click)="$event.stopPropagation();" [value]="tour.tourDate" [min]="today" [max]="nextYear" id="matInput" aria-labelledby="matInput"
									(dateChange)="onUpdateTourDate(tour,$event,tour.tourDate)"
									/>
									<mat-datepicker-toggle matSuffix [for]="picker" style="font-size: 23px;" >
									</mat-datepicker-toggle>
									<mat-datepicker #picker color="primary"></mat-datepicker>
								</mat-form-field>
							</div>
						</div>
						<!-- <div class="column2">
							<div class="tour-date">
							  <input id="matInput" type="date" class="date-picker title-date-picker"
									 (click)="$event.stopPropagation();" (change)="onUpdateTourDate($event)"
									 [value]="tour.tourDate | date: 'yyyy-MM-dd' "
									 [min]="min_date" max="{{ nextYear | date: 'yyyy-MM-dd' }}"
									 aria-labelledby="matInput">
							</div>
						</div> -->
				<div class="Vdivider2"></div>
						<div class="column3">
							<ng-container>
								<div class="show-prop-wrapper">
									<ng-container *ngIf="tour.showings.length > 1; else singleIcon">
										<img src="assets/mutiple-property-icon.svg" alt="Properties">
									</ng-container>
									<ng-template #singleIcon>
										<img src="assets/property-icon-list.svg" alt="Properties">
									</ng-template>
									<div>{{ tour.showings.length }}</div>
								</div>
							</ng-container>
							<!-- <ng-container *ngIf="tour.showings.length === 0; else showProperties">
								<p tabindex="0">
									No properties added
								</p>
							</ng-container>
							<ng-template #showProperties>
								<p tabindex="0" style="display:none;">
									Properties in the Tour : 
								</p>
								<div class="show-prop-wrapper">
								<img src="assets/property-icon-list.svg" alt="Properties"> <div>{{ tour.showings.length }}</div>
							</div>
							</ng-template> -->
						</div>
						<div class="Vdivider2"></div>
						<div class="column3">
							<ng-container>
								<div class="show-prop-wrappe" [ngClass]="{'disabled': tour.tourCompleted == 1}" (click)="tour.tourCompleted != 1 && navigateToMyDocument()">
									<ng-container>
										<img src="assets/group.png" width="28" alt="Properties">
									</ng-container>
								</div>
							</ng-container>
						</div>
						<div class="Vdivider2"></div>
						<div class="column2 tourDet-column">
							<!-- <button mat-stroked-button tabindex="0" type="button" class="btn-pad" (click)="SendRemainderEmail($event,tour)">Tour Details</button> -->
							<button mat-stroked-button tabindex="0" type="button" class="btn-pad" (click)="SendRemainderEmail($event, tour)" [disabled]="tour.showings.length === 0">
								&#9993;
								Tour Details
							 </button>
						</div>
						<div class="Vdivider2"></div>
						<div class="column4">
							<button title="Delete Tours" class="trash-button" mat-mini-fab (click)="DeleteTours($event,tour)">
								<img src="assets/trash.svg" alt="Delete" matSuffix [ngClass]="{'disabled': !tour.checked}" />
							</button>
						</div>
					</div>						
					</mat-panel-title>
				</mat-expansion-panel-header>
				<div *ngIf="tour.showings.length < 1">	
					This tour is so lonely! <a routerLink="../properties" [queryParams]="{tour: this.tour.id}">Please add a showing.</a>
				</div>
				<div *ngIf="tour.showings.length > 0" class="add-showing-button">
					<div class="add-buttons">
						<!-- <a routerLink="../properties" [queryParams]="{tour: this.tour.id}">
							<button mat-stroked-button tabindex="0">Add Showing</button>
						</a> 
						<a routerLink="../clients" [queryParams]="{tour: this.tour.id}">						 
							<button mat-stroked-button tabindex="0">Add Client</button>
						</a> -->
					    <button mat-stroked-button tabindex="0" class="btn-margin custom-font-size" type="button" *ngIf="tour?.clients?.length>0" [disabled]="tour.tourCompleted == 1" (click)="onOpenModal(tour)">LivePad Mobile Link</button>
						
						<button  mat-stroked-button class="ms-2 custom-font-size" *ngIf="tour.tourCompleted"  (click)="viewTourSummary(tour)">
							Tour Summary
						</button>
					</div>
				</div>
				<div class="row">
					<div class="col-sm-12 col-md-7 col-lg-7">
					<div *ngIf="tour.showings.length > 0">
						<div cdkDropList class="example-list" (cdkDropListDropped)="drop($event,tour)">
							<div class="example-box" *ngFor="let showing of tour.showings; let i = index"> 
							<app-showing-card class="example-box tour-list-box"  [propertyIndex]="i + 1" [tour]="tour" [showing]="showing" cdkDrag [requestActions]="requestActions" (onDelete)="deleteShowing($event)" (onRemoveClient)="removeClient($event)"  (onUpdateTime)="onUpdateShowingTime($event)" (onRequestShowing)="onRequested($event)"></app-showing-card>
							</div>
						</div>
					</div>
					</div>
					<div class="col-sm-12 col-md-5 col-lg-5">
						<div class="map-container">
							<div id="map-{{ tour.id }}" style="width: 100%; height: 100%;"></div>
						</div>
					</div>
				</div> 
			</mat-expansion-panel>
		</mat-accordion>
		<div class="align-error-message">
		<!-- <mat-chip-list aria-label="Clients for this tour">
			<mat-chip *ngFor="let client of tour.clients" color="primary" (click)="onClientChipClick(client.id)">
				{{client.firstName}} {{client.lastName}}
				<button mat-mini-fab class="client-delete" (click)="removeClient($event, client, tour)">
					<mat-icon class="client-delete-icon">clear</mat-icon>
				</button>
			</mat-chip>
		</mat-chip-list> -->
		<div class="error-message" *ngIf="tour.dateError">{{ tour.dateError }}</div>
	</div>
	</div>
</mat-list>
<div class="modal2" *ngIf="showAllClients">
    <div class="modal-content2 d-flex">
    <div class="modal-header">
		<h1>Clients in the Tour</h1>
		<span (click)="onCloseModal()" class="close" title="Close Modal"><img src="assets/close-icon.svg" alt="Close Icon"
            title="Close" class="close-icon" width="32px"></span>
     </div>
	 <div *ngFor="let client of selectedTour?.clients" class="client-row-popup">
		<div class="profile-pic-popup">
			<img *ngIf="client?.avatar?.url; else defaultAvatar" [src]="client.avatar.url" width="30" alt="Client Picture">
			<ng-template #defaultAvatar>
				<img src="./assets/avatar.jpg" width="30" alt="Client Picture">
			</ng-template>
		</div>
		<div class="info-section position-relative">
			<div class="full-name">{{ client.firstName }} {{ client.lastName }}</div>
			<button mat-mini-fab class="client-delete" (click)="removeClient($event, client, selectedTour)">
				<mat-icon class="client-delete-icon">clear</mat-icon>
			</button>
		</div>
	</div>
    </div>
</div>
<div class="modal" *ngIf="showTourSummaryModal">
    <div class="modal-content">      
      <div class="container">
        <h1 style="font-size: inherit;">LINK TO START TOUR</h1>
		<div class="row justify-content-center">
			<!-- <div class="col-md-12">
				<mat-form-field class="mt-3">
					<mat-label>Email</mat-label>
					
					<input type="email" matInput name="email" ngModel #email="ngModel" email pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$" (input)="onInputChanged()">
				  </mat-form-field>
				  <mat-form-field class="mt-3">
					<mat-label>Phone Number</mat-label>
					<input type="tel" (pasteError)="errorOutput($event)" matInput name="phoneNumber" ngModel #phoneNumber="ngModel" Formatphone (input)="onInputChanged()">
				  </mat-form-field>
				<br>
			</div> -->
			<div class="col-md-12">
				<mat-form-field class="mt-3">
				  <mat-label>Email</mat-label>
				  <!-- [ngModel]="selectedClients.email" -->
				  <input type="email" matInput name="email"  [ngModel]="selectedClients.email" #emailInput="ngModel"  (input)="onInputChanged()">
				</mat-form-field>
				<mat-form-field class="mt-3">
				  <mat-label>Phone Number</mat-label>
				  <!-- [ngModel]="selectedClients.phone" -->
				  <!-- <input type="tel" (pasteError)="errorOutput($event)" [ngModel]="selectedClients.phone" matInput name="phoneNumber"  #phoneInput="ngModel" Formatphone (input)="onInputChanged()"> -->
				  <input type="tel" (pasteError)="errorOutput($event)" [ngModel]="transform(selectedClients.phone)" matInput name="phoneNumber" Formatphone  #phoneInput="ngModel" (input)="onInputChanged()">
				</mat-form-field>
				<br>
			</div>
			<!-- <div class="col-sm-12">
				<div *ngIf="email.touched" class="error-message">
					<p *ngIf="email.errors?.pattern"><small>This is not a valid Email!!!</small></p>
				  </div>
				  <div *ngIf="showValidationPhone" class="error-message">
					<p><small>Please Enter valid Phone Number</small></p>
				</div>
				  <div *ngIf="showValidationError" class="error-message">
					<small>Please provide either a valid Email or Phone Number</small>
				</div>
				<div *ngIf="showPasteError" class="error-message">
					<small>Invalid phone number pasted</small>
				  </div>
			</div> -->
			<div class="col-sm-12">
				<div *ngIf="showValidationEmail" class="error-message">
					<p><small>This is not a valid Email!!!</small></p>
				</div>
				<div *ngIf="showValidationPhone" class="error-message">
				  <p><small>Please Enter a valid Phone Number</small></p>
				</div>
				<div *ngIf="showValidationError" class="error-message mb-1">
				  <p><small>Please provide either a valid Email or Phone Number</small></p>
				</div>
				<div *ngIf="showPasteError" class="error-message">
				  <p><small>Invalid phone number pasted</small></p>
				</div>
			</div>
		</div>
		<!-- <button type="submit" class="tour-complete-btn me-2" (click)="onSubmitGenerateLink(selectedTour,email.value,phoneNumber.value)">Send</button>  -->
		<button type="submit" class="tour-complete-btn me-2" (click)="onSubmitGenerateLink(selectedTour,emailInput.value, phoneInput.value)">SEND</button>
		<button type="submit" class="tour-cancel-btn" (click)="onCloseModal()">CANCEL</button> 
      </div>
    </div>
</div>

<div id="modal" class="modal1 alert-popup" *ngIf="openModal">
    <div class="modal-content3">
        <div class="container2">
            <h2 class="display-2">Alert</h2>
            <p class="my-5">e-Signature is incomplete. Do you want to proceed?</p>
            <div class="row signBtn" style="text-align: center;">
                <div class="col-md-6">
                    <button type="submit" tabindex="0" class="btn btnColor form-control btn-md delete-btn-color" (click)="navigateToMyDocument()"> YES
                    </button>
                </div>
                <div class="col-md-6"><button type="submit" tabindex="0"
                        class="btn signup form-control cancel-btn-color btn-md" (click)="onCloseModal()"> CANCEL
                    </button></div>
            </div>
        </div>
    </div>
</div>
</div>

<!-- <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.3.1/jquery.min.js"></script> -->
<!-- <script src="https://cdnjs.cloudflare.com/ajax/libs/moment.js/2.24.0/moment.min.js"></script> --> 
<!-- <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css" rel="stylesheet"> -->
<!-- <link href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.2/font/bootstrap-icons.css" rel="stylesheet"> -->
<!-- <script src="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.3.1/js/bootstrap.min.js"></script> -->
<!-- <script src="https://cdnjs.cloudflare.com/ajax/libs/eonasdan-bootstrap-datetimepicker/4.17.47/js/bootstrap-datetimepicker.min.js"></script> -->
<!-- <script id="rendered-js">
if (/Mobi/.test(navigator.userAgent)) {
  // if mobile device, use native pickers
  $(".date input").attr("type", "date");
  $(".time input").attr("type", "time");
} else {

// if desktop device, use DateTimePicker
  $("#datepicker").datetimepicker({
    useCurrent: false,
    format: "DD-MMM-YYYY",
    showTodayButton: true,
    icons: {
    next: "bi bi-chevron-right",
    previous: "bi bi-chevron-left",
    today: 'todayText' } });

  $("#timepicker").datetimepicker({
    format: "LT",
    icons: {
    up: "bi bi-chevron-up",
    down: "bi bi-chevron-down" } });
}
</script> -->