import { Component,OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { LoginService } from '../services/login.service';
import { User } from '../user';
import { AgentService } from '../services/agent.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { PasswordValidator } from '@app/validators/password.validator';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  changePasswordForm: FormGroup;
  user:User
  agent;
  error: string = null
  showSpinner = false
  private agentEmail: string;
  responseMessage: any;
  errorMessage: string;
  email_param:any;
  showPassword:boolean = false;
  showNewPassword:boolean = false;
  showOldPassword:boolean = false;
  constructor(private formBuilder: FormBuilder,
    private loginService: LoginService,
    private agentService: AgentService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog ) {
      this.user = loginService.getUser();
      let link = this.user?.agent?.link;
      if ( link != null ) {
        this.fetchExistingAgent(link)
      }
      this.agent = agentService.fetchAgent(link);
    }

  ngOnInit() {

    this.changePasswordForm = this.formBuilder.group({
      oldPassword: ['', Validators.required],
      newPassword: ['', [Validators.required, Validators.minLength(8), PasswordValidator.validate]],
      confirmPassword: ['', Validators.required],
    }, { validators: this.passwordMatchValidator });

  }
  toggleFieldType(field:string){
    switch (field) {
      case 'old':
        this.showOldPassword=!this.showOldPassword;
        break;
      case 'new':
        this.showNewPassword=!this.showNewPassword;
        break;
      case 'confirm':
        this.showPassword=!this.showPassword;
        break;
      default:
        break;
    }
  }
  fetchExistingAgent(link: string) {
    this.agentService
			.fetchAgent( link )
			.subscribe(response => { 
	  
        if ( response["errors"] != undefined ) {
	  				this.error = response["errors"][0].message
	  				if ( this.error.toLowerCase().includes('authenticated') ) {
						this.router.navigate(['/login'])
	  				} 
	  				return
	  			}

			    let foundAgent = response["data"]["agent"]

          this.agentEmail = foundAgent.email;

			    if ( foundAgent == null ) {
			    	this.router.navigate([ '/page-not-found'] )
			    	return
			    }

	  		})
	}

  checkPasswordStrength(control: AbstractControl): { [key: string]: any } | null {
    const password = control.value;
    if (password && password.length >= 8) {
      if (!/[A-Z]/.test(password) || !/[a-z]/.test(password) || !/\d/.test(password)) {
        return { weakPassword: true };
      }
    }
    return null;
  }

  passwordMatchValidator(control: AbstractControl): { [key: string]: any } | null {
    const newPassword = control.get('newPassword').value;
    const confirmPassword = control.get('confirmPassword').value;

    if (newPassword !== confirmPassword) {
      control.get('confirmPassword').setErrors({ passwordMismatch: true });
      return { passwordMismatch: true };
    } else {
      return null;
    }
  }

  changePasswordFormSubmit() {
    console.log('Email from queryParams------:', this.email_param);
    if (this.changePasswordForm.valid) {
      this.showSpinner = true;
      const oldPassword = this.changePasswordForm.get('oldPassword').value;
      const newPassword = this.changePasswordForm.get('newPassword').value;
      let agentEmail

      this.email_param = localStorage.getItem('resetEmail');

      if(this.email_param){
        agentEmail = this.email_param;
      }
      else{
        agentEmail = this.agentEmail;
      }
  
      this.agentService.changePassword(oldPassword, newPassword, agentEmail)
      .subscribe(
        response => {
          if (response["errors"] != undefined) {
            this.showSpinner = false;
            this.errorMessage = response["errors"][0].message;
            return;
          }

          const changePasswordResponse = response['data'].changePassword;
          if (changePasswordResponse !== null) {
            this.showSpinner = false;
            this.responseMessage = changePasswordResponse.message;

            const handleSuccessAndRedirect = () => {
              this.dialog.open(AlertDialogComponent, {
                data: { message: this.responseMessage },
              }).afterClosed().subscribe(() => {

                localStorage.removeItem('resetEmail');

                this.loginService.logout();
                this.router.navigate(['/login'], { queryParams: this.route.snapshot.queryParams });
              });
            };
            handleSuccessAndRedirect();
          }
        },
      );
    }
  }

  cancelChanges() {
    this.changePasswordForm.reset();
    this.router.navigate(['dashboard'])
  }
}
