import { Component, OnInit, EventEmitter, Output, Input ,ChangeDetectionStrategy} from '@angular/core';
// import { MatListModule } from '@angular/material/list';
// import { MatFormFieldModule } from '@angular/material/form-field';
// import { MatInputModule } from '@angular/material/input';
// import { MatCardModule } from '@angular/material/card';
// import { MatIconModule } from '@angular/material/icon';
// import { MatButtonModule } from '@angular/material/button';
// import { MatButtonToggleModule } from '@angular/material/button-toggle';
// import { PropertyCardComponent } from '../property-card/property-card.component'
import { ShowingConfirmationRequest, RequestActions } from '../showing'
import { UntypedFormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { ToursService } from '../services/tours.service'
import { SpinnerComponent } from '../spinner/spinner.component'
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Actions } from '../actions'

import { 
	 
	formatConfirmationTime, 
	appointmentDateTime,
	appointmentTime,
	appointmentDate,
	epochTimeFromDate,
	dateFromEpochTime,
	formatDate,
	formControlValueFromDate, 
	formatTime,
	checkForAuthError,
	convertToValidFormat} from '../utils'

interface SenderAndReceiver {
	sender: string;
	receiver: string;
}
import * as _moment from 'moment';
import { DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { MomentDateTimeAdapter, OWL_MOMENT_DATE_TIME_ADAPTER_OPTIONS } from 'ng-pick-datetime/date-time/adapter/moment-adapter/moment-date-time-adapter.class';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmComponent } from '@app/confirm/confirm.component';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const moment = (_moment as any).default ? (_moment as any).default : _moment;

export const MY_CUSTOM_FORMATS = {
    parseInput: 'LL LT',
    fullPickerInput: 'MM-DD-YYYY LT',
    datePickerInput: 'LL',
    timePickerInput: 'LT',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'MM-dd-YYYY LT',
    monthYearA11yLabel: 'MMMM YYYY',
};

@Component({
  selector: 'app-request-confirmation',
  templateUrl: './request-confirmation.component.html',
  styleUrls: ['./request-confirmation.component.css'],
  providers: [
	{provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE]},
	{provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS},
],
})
export class RequestConfirmationComponent implements OnInit {

	disableButton: boolean = false;

	private _confirmationRequest: ShowingConfirmationRequest
	set	confirmationRequest(value: ShowingConfirmationRequest) {

		this._confirmationRequest = value

		// No further action when setting null
		if ( this._confirmationRequest == undefined || this._confirmationRequest == null ) {
			return
		}

		// Set the instructions into the control
		if ( this._confirmationRequest.instructions != null ) {
			this.replyControl.setValue(this._confirmationRequest.instructions)
			this.replyControl.disable()
		}

		if ( this._confirmationRequest.counteredTime != null ) {

			let date = dateFromEpochTime(this._confirmationRequest.counteredTime)

			this.suggestedDateControl.setValue( formControlValueFromDate(date) )
			this.suggestedDateControl.disable()
			this.replyControl.disable()
		}


		// Viewed has already been marked and updated once.
		if ( Boolean(this._confirmationRequest.viewed) ) {
			return
		}

		// Auto mark as viewed and update on server
		value.viewed = true
		this._confirmationRequest = value

		this.updateRequest(this._confirmationRequest, false, -1)
	}
	get confirmationRequest(): ShowingConfirmationRequest {
		return this._confirmationRequest
	}

	error: string = null

	formatConfirmationTime = formatConfirmationTime

	title = "Viewing Appointment Confirmation"

	subtitle() { 
		return `Hello ${ this.receivingAgentName() }!`
	}

	firstButtonTitle() {
		return `Confirm ${ this.appointmentTime() }?`
	}

	header: string

	/**
 	 Show/hide the 
	*/
	showSpinner = false

	/**
 	 Date for when the listing agent counters with a new time
	*/
	suggestedTime: Date


	requestActions: RequestActions[] = []

	/**
 	 Form control for reply submission
	*/
	replyControl = new UntypedFormControl('')

	/**
 	 Control for the date
	*/
	suggestedDateControl = new UntypedFormControl('')


	today = new Date()

	nextYear = new Date(new Date().setFullYear(this.today.getFullYear() + 1))

	/**
	buttonStyle = {
		"min-height": "200px" 
	}*/

	logo = "./assets/livepad-logo-black.png"

	logoStyle = { 
		//"height" : "200px",
		"opacity": "0.2"
	}

	private _showReply: boolean = false
	set showReply(value) {
		this._showReply = value
	}
	get showReply(): boolean {

		if ( this.confirmationRequest?.instructions != null || this.confirmationRequest?.counteredTime != null ) {
			return true
		}

		return this._showReply
	}




	constructor(
		private toursService: ToursService,
		private route: ActivatedRoute,
		private router: Router,
		private dialog: MatDialog,
		private http: HttpClient
	) { }

	ngOnInit(): void {

		let confirmationToken = this.route.snapshot.paramMap.get('confirmationToken')

		// Get all the tours this agent has
		this.fetchConfirmationRequest(confirmationToken);

	}

	getCoordinatesFromAddress(address: string): Observable<any> {
		const accessToken = environment.map.mapbox.apiKey;
		const geocodingUrl = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(address)}.json?access_token=${accessToken}`;
	
		return this.http.get(geocodingUrl);
	}

	errorTimePassed (message) {
	    const dialogRef = this.dialog.open(ConfirmComponent, {
			data: {
		        message: `${message}`,
		        buttonText: {
					ok: "OKAY"
		        },
		        list: []
			}
	    });
	    return dialogRef.afterClosed()
	}

	// onDateTime(event){
	// 	console.log("object-dateTime",event);
	// 	this.suggestedDateControl.setValue( event.value )
	// }

	onDateTime(event,request){
		this.disableButton = false;
		console.log("object-dateTime",event);
		const date = formatDate( new Date(event.value))
		console.log("confirmationRequest.showings.property-----------",this.confirmationRequest.showings.property);
		const address = request?.showings?.property?.address?.fullAddress;
		if (address) {
			this.getCoordinatesFromAddress(address).subscribe(
			  (response: any) => {
				if (response.features && response.features.length > 0) {
				  const coordinates = response.features[0].geometry.coordinates;
				  const latitude = coordinates[1];
				  const longitude = coordinates[0];
				  console.log("latitude is", latitude);
				  console.log("longitude is", longitude);
				  	if ( latitude && longitude){
					this.toursService
					.fetchTimeZone(latitude,longitude)
					.subscribe( response => {
						let timeZone = response['data']['timeZone']
						const timeZoneTime = moment().tz(timeZone).format('MM-DD-YYYY HH:mm:ss');
						console.log("timezone time is",timeZoneTime);
						let currentTime;
						currentTime = moment(convertToValidFormat(date)).format('MM-DD-YYYY HH:mm:ss');
						if (timeZoneTime > currentTime) {
								console.log("comes to if conditon");
								this.disableButton = false;
								this.errorTimePassed(`Selected time is in the past. Please select a time in the future`);
								return;
						}
						else {
							console.log("comes to else condtion");
							this.disableButton = true
							this.suggestedDateControl.setValue( event.value );
						}
						
					})
					} else {
						this.disableButton = true
						this.suggestedDateControl.setValue( event.value );
					}
				}
			  });
		  } else {
			this.disableButton = true
			this.suggestedDateControl.setValue( event.value );
		  }
	}
	/**
 	 Fetch a single confirmation request by unique token
	*/
	fetchConfirmationRequest(confirmationToken) {

		this.toursService
			.fetchShowingConfirmationRequest( confirmationToken )
			.subscribe(response => { 
	  
	  	  			// Capture server error messages
	  			if ( response["errors"] != undefined ) {
	  				this.error = response["errors"][0].message
	  				return
	  			}  

			    this.confirmationRequest = response["data"].scheduler.confirmationRequest
			    if ( this.confirmationRequest == null || this.confirmationRequest == undefined ) {
	    			this.router.navigate([`/page-not-found`], { queryParams : this.route.snapshot.queryParams })		

			    }

		})
	}



	/**
 	 Submit a request to the receiving agent to confirm a showing time.
	*/
	requestCounterConfirmation(confirmationToken, action) {

		this.toursService
			.requestCounterAppointment(confirmationToken, action.code)
			.subscribe(response => {

	  			// Capture server error messages
	  			if ( response["errors"] != undefined ) {
	  				this.error = response["errors"][0].message
	  				return
	  			}

			})
	}

	/**
 	 Whether the confirm button should be disabled or not.
	*/
	shouldDisableConfirm() {
		if ( this.confirmationRequest?.responded == true && this.confirmationRequest?.instructions != null ) {
			return true
		}

		return false
	}

	/**
 	 Either the date or the response, but not both.
	*/
	shouldDisableSubmit() {

		if ( this.shouldDisableConfirm() ) { 
			return true 
		}

		if ( this.hasResponded() ) {
			return true
		}

		if ( this.replyControl.value?.length > 0 || this.suggestedDateControl?.value?.length > 0 || this.suggestedDateControl?.value ) {
			return false
		}

		return true
	}

	// Update request
	updateRequest(request, showSpinner, actionCode) {

		if ( showSpinner ) {
			this.showSpinner = true
		}

		if(request.counteredTime != null){
			this.toursService
			.updateShowingTime(request.showings.id,convertToValidFormat(request.counteredTime))
			.subscribe(response => {

	  			// Capture server error messages
	  			if ( response["errors"] != undefined ) {
					this.error = checkForAuthError( response["errors"], this.router )
	  				return
	  			}
		})
		}

		this.toursService
			.updateConfirmationRequest( request, actionCode )
			.subscribe(response => { 
	  
				this.showSpinner = false

	  	  			// Capture server error messages
	  			if ( response["errors"] != undefined ) {
	  				this.error = response["errors"][0].message
	  				return
	  			}  

			    this.confirmationRequest = response["data"].scheduler.updateConfirmationRequest

				if (this.confirmationRequest.requestActions.code == Actions.ListingAgentCounteredNotification.code ) {
					this.requestCounterConfirmation(request.confirmationToken, Actions.ListingAgentConfirmRequest)
				} 
				// if (this.confirmationRequest.requestActions.code == Actions.ShowingAgentCounteredNotification.code ) {
				// 	this.requestCounterConfirmation(request.confirmationToken, Actions.ShowingAgentConfirmRequest)
				// } 

		})
	}

	// Update request
	confirmRequestedTime(request, showSpinner) {

		if ( showSpinner ) {
			this.showSpinner = true
		}

		let nextAction = (() => {

			if ( request.requestActions.code == Actions.ShowingAgentConfirmRequest.code ) {
				return Actions.ListingAgentConfirmedNotification
			} else if ( request.requestActions.code == Actions.ListingAgentConfirmRequest.code ) {
				return Actions.ShowingAgentConfirmedNotification
			}

		})()

		request.requestActions = nextAction

		let previousRequest = null
		let len = request.showings.showingConfirmationRequest.length
		if ( len > 1 ) {
			previousRequest = request.showings.showingConfirmationRequest[len-2]
			request.confirmedTime = previousRequest.counteredTime
		}

		this.toursService
			.confirmRequestedTime( request )
			.subscribe(response => { 
	  
				this.showSpinner = false

	  	  			// Capture server error messages
	  			if ( response["errors"] != undefined ) {
	  				this.error = response["errors"][0].message
	  				return
	  			}  

			    this.confirmationRequest = response["data"].scheduler.confirmRequestedTime

		})
	}

	/**
 	 Determine which time is supposed to be displayed
	*/
	private displayedTime() {

		// Countered request, displayed time is the countered time from the last request.
		// This only works since a listing agent cannot originate the first showing.
		let len = this.confirmationRequest?.showings.showingConfirmationRequest.length
		if ( len > 1 ) {

			let previousRequest = this.confirmationRequest?.showings.showingConfirmationRequest[len-2]

			// return previousRequest.counteredTime
			return formatTime(previousRequest.counteredTime)
		}

		// First request, displayed time is the same as the showing
		// return this.confirmationRequest?.showings.showingTime
		return formatTime(this.confirmationRequest?.showings.showingTime)

	}


	/**
 	 Format out date and time for printing
	*/
	appointmentDateTime() {

		if ( this.confirmationRequest == undefined ) { return "" }

		// Uses utils.appointmentDateTime
		return this.displayedTime()

	}

	/**
 	 Format the month / day only
	*/
	appointmentDate() {

		if ( this.confirmationRequest == undefined ) { return "" }

		// Uses utils.appointmentDate
		return appointmentDate(this.displayedTime())

	}

	/**
 	 Just the time
	*/
	appointmentTime() {

		if ( this.confirmationRequest == undefined ) { return "" }

		// Uses utils.appointmentTime
		// return appointmentTime(this.displayedTime())
		return this.displayedTime()
	}

	onFirstButtonAction() {
		this.onConfirmAppointment()
	}

	onSecondButtonAction() {
		this.showReply = true
		this.replyControl.updateValueAndValidity()
		this.suggestedDateControl.updateValueAndValidity()
	}

	/**
 	 The person has sent a reply instead of confirming
	*/
	onSubmitReply(request) {

		if ( this.shouldDisableSubmit() ) {
			this.error = "You cannot send an empty response."
		}

		request.counteredTime = this.suggestedDateControl?.value?.length > 0 || this.suggestedDateControl?.value ? formatDate( new Date(this.suggestedDateControl.value) ) : null
		request.instructions = this.replyControl.value.length > 0 ? this.replyControl.value : null
		request.responded = true

		// What's the next action for a reply or date counter submission?
		let nextAction = (() => {

			// Showing agent is the originator
			if ( request.requestActions.code == Actions.ShowingAgentConfirmRequest.code ) {

				// If no time was provided we send this as a reply only
				if ( request.counteredTime == null ) {
					return Actions.ListingAgentRepliedNotification
				}

				// A counter for a new time was proposed. This will create a new confirmation request beginning
				// with the listing agent.
				return Actions.ListingAgentCounteredNotification

			// Reverse of the above
			} else if ( request.requestActions.code == Actions.ListingAgentConfirmRequest.code ) {

				if ( request.counteredTime == null ) {
					return Actions.ShowingAgentRepliedNotification
				}

				return Actions.ShowingAgentCounteredNotification
			}

		})()

		request.requestActions = nextAction

		// this.confirmationRequest = request

		// Lock the two controls once a reply has been submitted
		this.replyControl.disable()
		this.suggestedDateControl.disable()

		// Update this request
		this.updateRequest(request, true, nextAction.code)


		// // If this is a counter by either agent then we will start the cycle newly from their perspective.
		// if (request.requestActions.code == Actions.ListingAgentCounteredNotification.code ) {
		// 	this.requestCounterConfirmation(request.confirmationToken, Actions.ListingAgentConfirmRequest)
		// } 
		if (request.requestActions.code == Actions.ShowingAgentCounteredNotification.code ) {
			this.requestCounterConfirmation(request.confirmationToken, Actions.ShowingAgentConfirmRequest)
		} 


	}


	/**
 	 The listing agent has confirmed the appointment!
	*/
	onConfirmAppointment() {

		this.confirmationRequest.confirmed = true
		this.confirmationRequest.responded = true


		this.confirmRequestedTime(this.confirmationRequest, true)
	}


	/**
 	 Check on whether this appointment has been confirmed
	*/
	isConfirmed() {
		return this.confirmationRequest?.confirmed == true && this.confirmationRequest?.responded == true
	}

	/**
 	 Check whether the listing agent did not confirm but has responded
	*/
	hasResponded() {
		return this.confirmationRequest?.confirmed == false && this.confirmationRequest?.responded == true
	}

	/**
 	 Two cases where we hide the buttons and show a status instead.
	*/
	shouldShowButtons() {
		if ( this.isConfirmed() ) { return false }
		if ( this.hasResponded() ) { return false }
		return true
	}

	/**
 	 Who sent this request originally
	*/
	sendingAgentName() {
		return this.senderAndReceiver(this.confirmationRequest).sender
	}

	/**
 	 Text of the appointment day
	*/
	dateText() {
		return `on ${this.appointmentDate()}`
	}

	headerText() {

		if ( this.confirmationRequest == null || Actions == null ) {
			return ""
		}

		switch ( this.confirmationRequest?.requestActions?.code ) {

			case Actions.ListingAgentConfirmedNotification.code:
				return `${this.sendingAgentName()} is confirmed to view your listing at:`

			case Actions.ShowingAgentConfirmedNotification.code:
				return `${this.receivingAgentName()} is confirmed to view ${this.sendingAgentName()}'s listing at:`

			case Actions.ShowingAgentConfirmRequest.code:
			case Actions.ListingAgentRepliedNotification.code:
			case Actions.ListingAgentCounteredNotification.code:
				return `${this.sendingAgentName()} is requesting a viewing time for your listing at:`

			case Actions.ListingAgentConfirmRequest.code:
			case Actions.ShowingAgentCounteredNotification.code:
			case Actions.ShowingAgentRepliedNotification.code:
				return `${this.sendingAgentName()} is requesting a viewing time for their listing at:`

		}


	}

	/**
 	 Who is the receives this request originally
	*/
	receivingAgentName() {

		return this.senderAndReceiver(this.confirmationRequest).receiver

	}

	/**
 	 Sort out names for display
	*/
	private senderAndReceiver(request): SenderAndReceiver {

		let action = request?.requestActions

		if ( action == undefined ) { 
			return {
				sender: "",
				receiver: ""
			} 
		}

		switch ( action.code ) {

			// Actions that originated with the showing agent and the subsequent cycle
			case Actions?.ShowingAgentConfirmRequest.code:
			case Actions?.ListingAgentCounteredNotification.code:
			case Actions?.ListingAgentRepliedNotification.code:
			case Actions?.ListingAgentConfirmedNotification.code:
				return {
					receiver: request?.listAgent.fullName,
					sender: request?.showings.agent.fullName
				}
				break

			// Actions that originated with the listing agent and the subsequent cycle
			case Actions?.ListingAgentConfirmRequest.code:
			case Actions?.ShowingAgentCounteredNotification.code:
			case Actions?.ShowingAgentRepliedNotification.code:
			case Actions?.ShowingAgentConfirmedNotification.code:
				return {
					receiver: request?.showings.agent.fullName,
					sender: request?.listAgent.fullName
				}
				break
		}

		return {
			sender: "",
			receiver: ""
		} 
	}
}
