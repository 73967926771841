<mat-dialog-content>
	<p>
		{{message}}
	</p>
	<mat-selection-list #selection [multiple]="false" [(ngModel)]="selectionChoice">
	  <mat-list-option *ngFor="let item of list" [value]="item">
	    {{item}}
	  </mat-list-option>
	</mat-selection-list>
</mat-dialog-content>

<mat-dialog-actions align="center">
	<button mat-raised-button [disabled]="positiveButtonDisabled()" color="primary" (click)="onConfirmClick(selection)" tabindex="1">{{confirmButtonText}}</button>

	<button mat-raised-button mat-dialog-close *ngIf="extraButtonText != null" (click)="onExtraButton()" tabindex="-2">{{extraButtonText}}</button>
	<button mat-raised-button mat-dialog-close tabindex="-1">{{cancelButtonText}}</button>
</mat-dialog-actions>