import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MatSelect } from '@angular/material/select';
import { AdminService } from '@app/services/admin.service';
import { Organization } from '@app/user';
import { MlsService } from '@app/services/mls.service';
import { AgentService } from '@app/services/agent.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgForm } from '@angular/forms';
import { LoginService } from '@app/services/login.service';


@Component({
  selector: 'app-agent-profile-edit',
  templateUrl: './agent-profile-edit.component.html',
  styleUrls: ['./agent-profile-edit.component.css']
})
export class AgentProfileEditComponent {

  @Input() agent: any;
  
  @Input() mlsList: any[];
  orgList: Organization[];
  error: string = null

  showSpinner: boolean = true;
  isSuperAdmin: boolean = true;

  @Output()
	buttonAction: EventEmitter<any> = new EventEmitter<any>()

  editButtonPressed = false
  // isEditing = false

  constructor(private router: Router,
    private adminService: AdminService,
		private mlsService: MlsService,
    private snackBar: MatSnackBar,
    private agentService: AgentService,
    private loginService: LoginService) {}

  ngOnInit() {
  let roleId = this.loginService.user.orguserdetails[0].roleId;
  if(roleId === 1){
    this.isSuperAdmin = true;
  }else{
    this.isSuperAdmin=false;
  }
  console.log("roleId is",roleId);
  this.fetchMlsList()
  this.fetchOrgList();  
  // Simulate data loading or rendering tasks
    setTimeout(() => {
      this.showSpinner = false; // Set showSpinner to false to hide the spinner
    }, 1000); // Adjust the duration as needed
  }

  formatPhoneNumber(phoneNumber) {
    if (phoneNumber && phoneNumber.length === 10) {
        const formattedNumber = phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
        return formattedNumber;
    }
    return phoneNumber;
  }

  isValidImageUrl(url: string): boolean {
    const regex = /^(https:\/\/d3o40wwl2vh905\.cloudfront\.net\/assets\/images\/avatar\/agents\/\d+_\d+\.(jpg|png|jpeg))|(blob:.+)$/i;
    return regex.test(url);
  }

  hasLicenseWithLicenseNumber(): boolean {
    return this.agent.license && this.agent.license.some(license => license.licenseNumber);
  }

  getTitle() {
		return `${this.agent?.firstName} ${this.agent?.lastName}`
	}


  isEditing() {
		return this.editButtonPressed == true
	}

  onClick(event) {

		if (this.buttonAction != null) {
			this.buttonAction.emit(event)
		}
	}
  
  openModal:boolean = false;
	onRequestAccess(){
		this.openModal = true;
	}
	onCloseModal(){
		this.openModal = false;
	}

  fetchMlsList() {

    this.error = null

  this.mlsService
    .fetchMlsList()
    .subscribe( (response) => {

        // Capture server error messages
        if ( response["errors"] != undefined ) {
          this.error = response["errors"][0].message
          return
        }

        this.mlsList = response['data']['contractedMlsList']

    })
  }


  fetchOrgList() {
    this.error = null;
    this.adminService.orgList().subscribe((orgList) => {
      // Capture server error messages
      if (orgList['errors'] != undefined) {
      this.error = orgList['errors'][0].message;
      return;
      }
      this.orgList = orgList['data']['getOrgsList'];
      // this.orgList = this.orgList.filter((organization) => organization.status === 1);
  
      const noneOfTheAbove = orgList['data']['getOrgsList'].find(organization => organization.id == 1);
      this.orgList = orgList['data']['getOrgsList'].filter(
        (organization) => organization.status === 1 && organization.id != 1
      ).sort((a, b) => a.name.localeCompare(b.name));
  
      console.log("orglist is------~~~~~~~~~",this.orgList);
      if (noneOfTheAbove) {
        noneOfTheAbove.name = "None of the Above";
        this.orgList.push(noneOfTheAbove);
      }
  
      console.log("orglist is------~~~~~~~~~",this.orgList);
      // this.orgList.forEach((organization) => {
      //   if (organization.id == 1) {
      //     organization.name = "None of the Above";
      //   }
      // });
    });
    }

  onOrganizationSelected(event: MatSelect) {
    console.log("event",event);
    const selectedOrgId=event.value.id
			this.adminService.getOrgDetail(selectedOrgId).subscribe((res) => {
				const orgmls = res['data']['getOrgData'][0]['orgmls'];
        console.log("orgmls",orgmls);
				if (Array.isArray(orgmls)) {
					this.mlsList = orgmls.map(item => item.contractedMls);
				} else if (orgmls && orgmls.contractedMls) {
					this.mlsList = [orgmls.contractedMls];
				} else {
					this.mlsList = [];
				}
			});
	  }


    onSendRequest(f: NgForm): void {
      if(f.form.invalid) return;
        console.log("object",f.form.value);
        const selectedOrg = f.form.value.selectOrg;
        const selectedMls2 = f.form.value.selectMls;
			  
				console.log('Selected MLS 1:', selectedOrg);
				console.log('Selected MLS 2:', selectedMls2);
        console.log("agent details is",this.agent);

        const mlsId: string[] = selectedMls2.map(mls => mls.id);
        console.log("mls id is",mlsId);

        this.agentService
        .addMlsRequest(this.agent,selectedOrg.id,mlsId)
        .subscribe( (response) => {
          console.log("reponse is",response);

            // Capture server error messages
            if ( response["errors"] != undefined ) {
              this.error = response["errors"][0].message
              this.snackBar.open(response["errors"][0].message , 'Close', {
                duration: 5000, // notification will automatically close after 5 seconds
                horizontalPosition: 'center', // position the notification at the right corner of the screen
                verticalPosition: 'top', // position the notification at the top of the screen
              });
              return
            }

            this.snackBar.open(response['data']['addMlsRequest'].message , 'Close', {
              duration: 5000, // notification will automatically close after 5 seconds
              horizontalPosition: 'center', // position the notification at the right corner of the screen
              verticalPosition: 'top', // position the notification at the top of the screen
            });
        })
		    this.openModal = false;
	  
		// You can now access the selected values for both mat-select elements.
	}
}