<div class="cursor border p-1 me-2 drag-icon-sm" cdkDragHandle>
    <div class="menu-bar"></div>
    <div class="menu-bar"></div>
    <div class="menu-bar"></div>
</div>
<div class="property-serial-number">
    {{propertyIndex}}
</div>
<a routerLink="../property/{{showing.property.listingKey}}"> 
	<img class="photo-showing-thumb" src="{{ firstOf(showing.property.propertyPhotos)?.url }}" onerror="this.src='./assets/no_photo_455x340.jpg'" alt="User Picture" />
</a>
<div [ngClass]="(showing.showingConfirmationRequest.length > 0)?'cell-container request-sent':'cell-container'">
	<div class="divided">
		<div width="200px">{{ addressStreet(showing.property.address.fullAddress) }}</div>
		<div width="200px">{{ addressCity(showing.property.address.fullAddress) }}</div>
	</div>
    <!-- <input class="divided date-picker" type="time" [disabled]="showing.showingConfirmationRequest.length > 0" [value]="showing.showingTime | date:'HH:mm' " 
		(change)="onUpdateShowingTime(showing, tour, $event)" id="datePicker" aria-labelledby="datePicker"> -->
	<div class="input-group input-group-sm mb-3 timepicker-input-width" aria-label="time picker">
		<input [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1" placeholder="Time" class="form-control"
		[attr.disabled]="showing.showingConfirmationRequest.length > 0" [value]="showing.showingTime"
		(dateTimeChange)="onUpdateShowingTime(showing, tour, $event)" id="timePicker" aria-labelledby="timePicker"
		[readonly]="showing.showingConfirmationRequest.length > 0"
		[disabled]="showing.showingConfirmationRequest.length > 0"
		>
		<span class="input-group-text bg-white" [owlDateTimeTrigger]="dt1" *ngIf="!(showing.showingConfirmationRequest.length > 0)">
			<mat-icon aria-hidden="false" aria-label="Example thumbs up SVG icon">
				av_timer
			</mat-icon>
		</span>
		<owl-date-time [pickerType]="'timer'" [hour12Timer] = "'true'"  #dt1 [disabled]="showing.showingConfirmationRequest.length > 0"></owl-date-time>
	</div>
	<div class="divided">
		{{ showing.instructions }}
	</div>
</div>
<div class="flex-container">
	<div class="button-with-label">
		<div class="button-container margin-bottom-8" (click)="onWrapperClicked(showing)">
			<button mat-mini-fab [color]="confirmedStyle(showing, datePickerValue).color" [style]="confirmedStyle(showing, datePickerValue).style" aria-label="Has the showing been confirmed with the listing agent?">
			  <mat-icon matSuffix>{{confirmedStyle(showing, datePickerValue).icon}}</mat-icon>
			</button>
		</div>
		<mat-label class="divided">{{confirmedStyle(showing, datePickerValue).title}}</mat-label>
	</div>
	<div class="button-with-label">

    	<div class="button-container margin-bottom-8">
			<button mat-mini-fab color="warn" aria-label="Delete this showing" (click)="deleteShowing(showing, tour)">
		        <mat-icon>delete_forever</mat-icon>
			</button>
		</div>
		<mat-label  class="divided">Delete</mat-label>
	</div>
</div>
<mat-error>{{error}}</mat-error>
