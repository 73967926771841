<mat-card>
  <mat-card-content>
    <div class="content-container">
      
      <div class="column column-2">
        <div class="column-2-top">
          <div #address class="address-button-wrapper">
            <div>
            <!-- <div class="address-item address-header">
              {{ (property?.address.streetNumber && property?.address.streetName) ? property?.address.streetNumber + ' '
              + property?.address.streetName : 'N/A' }}
            </div> -->
            <div class="address-item address-header">
              {{
                (property?.address.streetNumber && property?.address.streetName)
                  ? property?.address.streetNumber + ' ' + property?.address.streetName
                  : (property?.address.streetNumber && !property?.address.streetName)
                    ? 'N/A'
                    : (property?.address.streetName && property?.address.streetName !== "null " && property?.address.streetName !== "null" && property?.address.streetName !== "none " && property?.address.streetName !== "none" ? property?.address.streetName : 'N/A')
              }}
            </div>
            <div class="address-item address-footer">
              {{property?.address.city}}{{property?.address.city?', ':''}}{{property?.address.state}}{{property?.address.state?', ':''}}{{property?.address.zip}}
            </div>
            </div>
            <div class="rightTour">
              <button class="addtour me-2" mat-flat-button [disabled]="isWishlistBtnClicked" (click)="isAddToWishList?removeWishlist(property):onAddToWishList(property)" tabindex="0">
                <i [class]="isAddToWishList?'bi bi-check text-success':'bi bi-plus'"></i> <span>{{isAddToWishList?'Added':'Add'}} to Wishlist</span>
              </button>
              <button class="addtour" mat-flat-button (click)="onAddToTour(property)" tabindex="0">
                <i class="bi bi-plus"></i> <span>Add to tour</span>
              </button>
            </div>
          </div>
         
          <div class="row ImageGrid-price_wrapper">
            <div class="col-md-8 col-lg-9">
            <app-image-grid *ngIf="property != null" [images]="property.propertyPhotos"></app-image-grid>
            </div>
            <div class="col-md-4 col-lg-3">
            <div id="price">
              <span class="price-title">PRICE:</span> <span class="price-style">{{ formatPrice(property?.propertyFeatures['featureData'].ListPrice) }}</span>
              <span [class]="priceChangeColor(property)" style="padding: 0 3px;font-size: 22px;">{{priceChangeArrow()}}</span>
            </div>
            <div >
              <div class="other-details-title">Other Details:</div>
              <div id="highlights">
                <div class="highlight-tile-wrapper mt-4">
                <div class="highlight-item beds">
                  <!-- <mat-icon prefix>single_bed</mat-icon> -->
                  <img src="assets/bed-icon.svg" alt="Bath Icon" class="icon-style">
                  <p>Bedrooms: {{ property?.propertyFeatures['featureData'].BedroomsTotal || 'N/A'}}</p>
                </div>
                <div class="highlight-item baths">
                  <!-- <mat-icon prefix>bathtub</mat-icon>                   -->
                  <img src="assets/bath-icon.svg" alt="Bath Icon" class="icon-style">
                  <p>Bathrooms: {{ bathroomCalculation(property) }}</p>
                </div>
              </div>
              <div class="highlight-tile-wrapper mt-4">
                <!-- <div class="highlight-item sqft">
                  <img src="assets/sqfeet-icon.svg" alt="sqft Icon" class="icon-style">
                  <p>Sq Ft: {{ property?.propertyFeatures['featureData'].LivingArea || 'N/A' }}</p>
                </div> -->
                <div class="highlight-item sqft">
                  <img src="assets/sqfeet-icon.svg" alt="sqft Icon" class="icon-style">
                  <p>Sq Ft: 
                    {{ (environment.originatingMlsList.includes(property?.originatingMls?.toLowerCase())) 
                      ? (property?.propertyFeatures['featureData'].BuildingAreaTotal || 'N/A') 
                      : (property?.propertyFeatures['featureData'].LivingArea || 'N/A') }}
                  </p>
                </div>
                <div class="highlight-item lot">
                  <!-- <mat-icon prefix>terrain</mat-icon>                   -->
                  <img src="assets/lotsize-icon.svg" alt="lot Icon" class="icon-style">
                  <p>Lot Size: {{ property?.propertyFeatures['featureData'].LotSizeAcres || 'N/A' }}</p>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="other-details-title">About the Property:</div>
            <div class="left details-container">
              <p class="formatted-remarks"
                [innerHTML]="formatRemarks(property?.propertyFeatures['featureData'].PublicRemarks)"></p>
             
            </div>
          </div>
        </div>
        <div class="map-add-details-wrap">

          <div class="width50">
            <div class="other-details-title">Locate Property:</div>
          <div id="map" class="mt-2">
              <!-- <google-map height="250px" width="100%" [center]="property?.center">
                <map-marker [position]="property?.center"></map-marker>
              </google-map>  -->
            </div>
          </div>

          <div class="width50">
          <div class="other-details-title pl-2">Additional Details:</div>
            <div class="details-wrapper mt-2">
              <div class="detail-agent col-md-12">
                <div class="row">
                  <div><strong>Details of Seller's Agent :</strong></div>
                  <div class="col-sm-12" style="border: black 1px;">
                    <div class="mt-3" tabindex="0"><div >Name : {{property?.propertyFeatures.featureData.ListAgentFullName ?? "N/A"}}</div></div>
                    <div class="mt-3" tabindex="0"><div >Email : {{property?.propertyFeatures.featureData.ListAgentEmail ?? "N/A"}}</div></div>
                    <div class="mt-3" tabindex="0"><div >Phone : {{property?.propertyFeatures.featureData.ListAgentPreferredPhone ?? property?.propertyFeatures.featureData.ListAgentDirectPhone ?? "N/A"}}</div></div>
                  </div>
                  <!-- <div class="col-md-9 ps-0">
                    <div class="mt-3" tabindex="0">
                      <div class="mt-3" tabindex="0"><div >{{property?.propertyFeatures.featureData.ListAgentFullName ?? "N/A"}}</div></div>
                      <div class="mt-3" tabindex="0"><div >{{property?.propertyFeatures.featureData.ListAgentEmail ?? "N/A"}}</div></div>
                      <div class="mt-3" tabindex="0"><div >{{property?.propertyFeatures.featureData.ListAgentPreferredPhone ?? "N/A"}}</div></div>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
            <div class="details-wrapper mt-1">
              <div class="detail-item col-md-6" *ngFor="let detail of propertyDetails(property) | keyvalue">
                <!-- <div><strong>{{detail.key}}: </strong>{{detail.value}}</div> -->
                <div *ngIf="detail.key === 'View'">
                  <strong>{{detail.key}}: </strong>{{detail?.value}}
                </div>
                <div *ngIf="detail.key !== 'View'">
                  <strong>{{detail.key}}: </strong>{{detail.value}}
                </div>
              </div>
              <div class="detail-item col-md-6">&nbsp;</div>
            </div>
        </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            
            
          </div>
          <div class="col-md-6">
            
           
            </div>
        </div>
      </div>
    </div>


    <div class="container mt-2">
      <div class="column column-1">

      </div>
      <!-- <div class="column column-2">
        <div class="column-2-top">
          <div #address>
             <div class="address-item address-header">
              {{property?.address.streetNumber}} {{property?.address.streetName}} {{property?.address.unitNumber}}
            </div> -->
            <!-- <div class="address-item address-header">
              {{ (property?.address.streetNumber && property?.address.streetName) ? property?.address.streetNumber + ' '
              + property?.address.streetName : 'N/A' }}
            </div>
            <div class="address-item address-footer">
              {{property?.address.city}}, {{property?.address.state}} {{property?.address.zip}}
            </div>
          </div>
          <div id="price">
            {{ formatPrice(property?.propertyFeatures['featureData'].ListPrice) }}
            <span [class]="priceChangeColor(property)">{{priceChangeArrow()}}</span>
          </div>

        </div> -->
        <!-- Map -->
        <!-- <div id="map">
          <google-map height="350px" width="100%" [center]="property?.center">
            <map-marker [position]="property?.center"></map-marker>
          </google-map> 
        </div>
      </div> -->
      <!-- Column 3 -->

     
      <div class="column column-3">
        <!-- Star Ratings & Heart -->
        <div class="right rating-and-stars" *ngIf="clientId != null">
          <div class="star-set">
            <mat-icon *ngFor="let i of [1,2,3,4,5]" class="empty-star {{ getStarStatus(i) }}">{{ getStarStatus(i)
              }}</mat-icon>
          </div>
          <div class="heart-set">
            <mat-icon *ngIf="clientId != null" class="heart {{ getFavStatus() }}">{{ getFavStatus() }}</mat-icon>
          </div>
        </div>
        <!-- Property details in text format -->
        <!-- <div class="left details-container">
          <p *ngFor="let remarks of formatRemarks(property?.propertyFeatures['featureData'].PublicRemarks)">
            {{remarks}}
          </p>
          <div class="detail-item" *ngFor="let detail of propertyDetails(property) | keyvalue">
            <div><strong>{{detail.key}}: </strong>{{detail.value}}</div>
          </div>
        </div> -->
        
        <!-- Client notes & comments -->
        <div *ngIf="clientId != null" class="client-comments">
          <mat-list *ngFor="let comment of property?.clientComments">
            <mat-card>
              <mat-card-title>{{comment.clients.firstName}} said,</mat-card-title>
              <mat-card-subtitle>{{ formatTimestamp(comment.createdAt) }}</mat-card-subtitle>
              <mat-card-content>
                "{{comment.comments}}"
              </mat-card-content>
            </mat-card>
          </mat-list>
        </div>
        <div *ngIf="clientId != null" class="client-photos">
          <app-image-grid *ngIf="property != null" [images]="property.clientPhotos"
            (onImageClick)="onOpenClientImages($event)"></app-image-grid>
        </div>
      </div>
    </div>
  </mat-card-content>
  <mat-card-footer>
    <mat-error>{{error}}</mat-error>
  </mat-card-footer>
</mat-card>

<app-spinner [show]="showSpinner"></app-spinner>