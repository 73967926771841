import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[NumberOnly]'
})
export class StrictNumberOnlyDirective {

  // private regex: RegExp = new RegExp('^[0-9]{0,10}$');
  private regex: RegExp = new RegExp('^(?=.*[1-9])[0-9]{0,10}$');

  constructor(private elementRef: ElementRef) { }

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    const inputVal: string = this.elementRef.nativeElement.value;
    if (event.key === 'Backspace') {
      return;
    }
    const inputValue: string = inputVal.concat(event.key);
    if (inputValue && !String(inputValue).match(this.regex)) {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event']) onPaste(event) {
    const clipboardData = (event.originalEvent || event).clipboardData.getData('text/plain');
    if (clipboardData) {
      const regEx = new RegExp('^[0-9]*$');
      if (!regEx.test(clipboardData)) {
        event.preventDefault();
      }
    }
  }

  @HostListener('ngModelChange', ['$event']) onInputChange(event) {
    if(event){
    const inputVal = event.replace(/\D/g, '').substring(0, 10);
      if (inputVal) {
        this.elementRef.nativeElement.value = inputVal.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
      } else {
        this.elementRef.nativeElement.value = '';
      }
    }
  }
}