<div class="content-container">
	<div class="dashboard">
		<div class="title">
			<img src=" assets/dashboard-icon.svg" alt="Dashboard Icon" width="40px" tabindex="0">
			<h1 tabindex="0">DASHBOARD</h1>
		</div>
		<div class="container container-height">
		<div class="formMargin" align="center">
			<div class="centertoleft parent-div" (click)="gotoTour()" role="icon" role-describe="TOURS">
				<img src="assets/form_background.svg" alt="Image" class="bg-img" tabindex="0">	
				<div class="child-div">							
				<div class="img-align">
					<img src="assets/tours-icon.svg" class="hvr-push" alt="Tours Icon" width="50%" tabindex="0">
				</div>
					<p class="mt-3 mobile-margin" tabindex="0">TOURS</p>	
					</div>			
			</div>

			<div (click)="gotoClient()" role="icon" class="parent-div show-first mid-div" role-describe="CLIENTS">
				<img src="assets/form_background.svg" alt="Image" class="bg-img" tabindex="0">
				<div class="child-div">	
				<div class="img-align">	
					<img src="assets/clients-icon.svg" alt="Clients Icon" class="hvr-push" width="60%" tabindex="0">
				</div>
					<p class="mt-3 mobile-margin" tabindex="0">CLIENTS</p>
				</div>
			</div>

			<div class="centertoright parent-div" (click)="gotoProperties()" role="icon" role-describe="PROPERTIES">
				<img src="assets/form_background.svg" alt="Image" class="bg-img" tabindex="0">
				<div class="child-div">	
				<div class="img-align">	
					<img src="assets/locate-icon.svg" alt="Properties Icon" class="hvr-push" width="30%" tabindex="0">
				</div>
					<p class="mt-3 mobile-margin" tabindex="0">PROPERTIES</p>
				</div>
			</div>
			<div class="centertoright parent-div"  *ngIf="isAdmin || isSuperAdmin" (click)="adminpage()" role="icon" role-describe="ADMIN">
				<img src="assets/form_background.svg" alt="Image" class="bg-img" tabindex="0">
				<div class="child-div">
				<div class="img-align">        
					<img src="assets/admin-icon.svg" alt="Admin Icon" class="hvr-push" width="30%" tabindex="0">
				</div>
					<p class="mt-3 mobile-margin" tabindex="0">ADMIN</p>
				</div>
			</div>
			<div class="centertoright parent-div"   (click)="gotoMyDoc()" role="icon" role-describe="DOCUMENTS">
				<img src="assets/form_background.svg" alt="Image" class="bg-img" tabindex="0">
				<div class="child-div">
				<div class="img-align">        
					<img src="assets/my-document-icon.svg" alt="Admin Icon" class="hvr-push" width="30%" tabindex="0">
				</div>
					<p class="mt-3 mobile-margin" tabindex="0">DOCUMENTS</p>
				</div>
			</div>
			
		</div>
	</div>
		<div class="welcome" tabindex="0">
			<label class="horizontalline"><b class="adafix">.</b></label>
			Welcome
			<label class="horizontalline"><b class="adafix">.</b></label>
		</div>
		<div class="line"></div>
	</div>
</div>