<div class="propSearch mt-3">
	<h2 [ngClass]="{ 'green': properties.length > 0, 'red': properties.length === 0 }" tabindex="0">
		{{ properties.length === 1 ? properties.length.toLocaleString() + ' matching listing found' : properties.length.toLocaleString() + ' matching listings found' }}
	</h2>
 
	<div class="popUp mt-3 mb-3">
		<div class="rightTour">
			<button *ngIf="showAddToTours" 
					class="editprofile" 
					mat-flat-button  
					[disabled]="shouldDisableAddToTour()"
					(click)="onAddToTour()" tabindex="0">
			  {{addToTourTitle()}}
			  <i class="bi bi-plus-square plusicon" 
   				style="color:#0C4675;padding-left: 5%; font-size: 35px;"
   				[ngClass]="{'disabled-icon': shouldDisableAddToTour()}"></i>
			</button>
		</div>
	</div>
	<div class="tour-button-container float-right">
		<span class="left-align error" *ngIf="error">{{error}}</span>
	</div>
	<div class="contentBox">
		<div class="filter">
			<!-- <table mat-table #searchTable matSort [dataSource]="properties" class="client-table-list"
				(matSortChange)="onSortChange($event, searchTable)"> -->
				<div class="table-responsive properties-scroll">
			<table mat-table #searchTable matSort [dataSource]="dataSource" class="client-table-list"
				(matSortChange)="onSortChange($event, searchTable)" id="clientPropertiesList" aria-describedby="clientPropertiesList">
				<ng-container *ngIf="showCheckbox" matColumnDef="checkbox">
					<th mat-header-cell *matHeaderCellDef>
						<b class="adafix">.</b>
						<mat-checkbox [disabled]="properties?.length < 1" class="client-title tour-checkbox" [checked]="isAllCheckBoxChecked()"
							(change)="onSelectAllProperties($event)" id="propertyCheckboxHead" aria-labelledby="propertyCheckboxHead"><b class="adafix">.</b></mat-checkbox>
					</th>
					<td mat-cell *matCellDef="let property" class="checkbox-cell">
						<mat-checkbox class="client-title tour-checkbox" [(ngModel)]="property.checked"
							(click)="$event.stopPropagation();" (change)="onPropertyChecked(property)"><b class="adafix">.</b></mat-checkbox>
					</td>
				</ng-container>
				<ng-container matColumnDef="address">
					<th mat-header-cell *matHeaderCellDef style="font-size: 17px; font-weight: bold; width:300px; cursor: default !important;" tabindex="0">Address</th>
					<td mat-cell *matCellDef="let property" tabindex="0"> {{formatValue( property, 'address')}} </td>
				</ng-container>
				<ng-container matColumnDef="status">
					<th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 17px; font-weight: bold;" tabindex="0">Status</th>
					<td mat-cell *matCellDef="let property" tabindex="0"> {{formatValue( property, 'status')}} </td>
				</ng-container>
				<ng-container matColumnDef="price">
					<th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 17px; font-weight: bold;" tabindex="0">Price</th>
					<td mat-cell *matCellDef="let property" tabindex="0"> {{formatValue( property, 'price')}} </td>
				</ng-container>
				<ng-container matColumnDef="price-change">
					<th mat-header-cell *matHeaderCellDef style="font-size: 17px; font-weight: bold; cursor: default !important;" tabindex="0">$&nbsp;&nbsp;Change&nbsp;</th>
					<!-- <td mat-cell *matCellDef="let property" tabindex="0"> {{formatValue( property, 'price-change')}} </td> -->
					<td mat-cell *matCellDef="let property" tabindex="0" [class]="priceChangeColor(property)"> {{formatValue( property, 'price-change')}}</td>
				</ng-container>
				<ng-container matColumnDef="bed-bath">
					<th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 17px; font-weight: bold;" tabindex="0">&nbsp;Beds/Baths</th>
					<td mat-cell *matCellDef="let property" tabindex="0"> {{formatValue( property, 'bed-bath')}} </td>
				</ng-container>
				<ng-container matColumnDef="sqft">
					<th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 17px; font-weight: bold;" tabindex="0">Sq&nbsp;&nbsp;Ft</th>
					<td mat-cell *matCellDef="let property" tabindex="0"> {{formatValue( property, 'sqft')}} </td>
				</ng-container>
				<ng-container matColumnDef="lot">
					<th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 17px; font-weight: bold;" tabindex="0">Lot</th>
					<td mat-cell *matCellDef="let property" tabindex="0"> {{formatValue( property, 'lot')}} </td>
				</ng-container>
				<ng-container matColumnDef="year-built">
					<th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 17px; font-weight: bold;" tabindex="0">Year&nbsp;Built</th>
					<td mat-cell *matCellDef="let property" tabindex="0"> {{formatValue( property, 'year-built')}} </td>
				</ng-container>
				<ng-container matColumnDef="dom">
					<th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 17px; font-weight: bold;" tabindex="0">Dom</th>
					<td mat-cell *matCellDef="let property" tabindex="0"> {{formatValue( property, 'dom')}} </td>
				</ng-container>
				<ng-container matColumnDef="agent-name">
					<th mat-header-cell *matHeaderCellDef style="font-size: 17px; font-weight: bold; cursor: default !important;" tabindex="0">Agent</th>
					<td mat-cell *matCellDef="let property" tabindex="0"> {{formatValue( property, 'agent-name')}} </td>
				</ng-container>
				<ng-container matColumnDef="firm">
					<th mat-header-cell *matHeaderCellDef style="font-size: 17px; font-weight: bold; cursor: default !important;" tabindex="0">Firm</th>
					<td mat-cell *matCellDef="let property" tabindex="0"> {{formatValue( property, 'firm')}} </td>
				</ng-container>
				<ng-container matColumnDef="mls">
					<th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 17px; font-weight: bold;" tabindex="0">MLS#</th>
					<td mat-cell *matCellDef="let property" tabindex="0"> {{formatValue( property, 'mls')}} </td>
				</ng-container>
				<ng-container matColumnDef="caret">
					<th mat-header-cell *matHeaderCellDef style="font-size: 17px; font-weight: bold;"><b class="adafix">.</b></th>
					<td mat-cell *matCellDef="let property">
						<mat-icon matSuffix>chevron_right</mat-icon>
					</td>
				</ng-container>
				
				<tr mat-header-row *matHeaderRowDef="displayedColumns"><b class="adafix">.</b></tr>
				<tr mat-row *matRowDef="let client; columns: displayedColumns;" class="client-table-row"
					(click)="onRowClick(client)"><b class="adafix">.</b>
				</tr>
			</table>
		</div>
		</div>
	</div>
	<!-- <button *ngIf="properties.length > pageSize && properties.length > currentPage * pageSize" (click)="showMore()" class="btn btn-primary">Show more</button> -->
	
</div>
<!-- <div class="showmore-btn">	
		<button *ngIf="properties.length > pageSize && properties.length > currentPage * pageSize" (click)="showMore()" class="btn signup form-control" tabindex="0"> SHOW MORE </button>	
</div> -->
<!-- <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 15, 20]"></mat-paginator> -->
<div class="d-flex justify-content-around align-items-center flex-column flex-md-row bg-white" >
	<div class="form-group" style="width: 8.5rem;display:flex;align-items:center;color:rgba(0,0,0,.54);margin-top: 20px;" >
		<label for="paginationInput">Page </label>
		<input  #i maxlength="10" pattern="\d*"  	
			id="paginationInput" 
			class="form-control py-0 ms-1 px-1" 
			type="text"
			[(value)]="pageNumber"
			(keyup.enter)="selectPage(i.value)"
			(blur)="selectPage(i.value)"
			(input)="formatInput($event,i)"
			aria-labelledby="paginationInputLabel paginationDescription"
			[style.width]="totalNumberOfPages.toString().length+'.1rem'"
			autocomplete="off"
			/>
			<span id="paginationDescription" class="col-form-label text-nowrap px-2"> of {{totalNumberOfPages }}</span>
		</div>
	<mat-paginator 
		#paginator
		[pageSize]="pageSize"
		[pageSizeOptions]="[5, 10, 15, 20]"
		[showFirstLastButtons]="true" 
		(page)="onPageChange($event)"
		[length]="properties?.length"
		[hidePageSize]="false"
		[pageIndex]="pageIndex"
		[disabled]="false"
		aria-label="Select page"
	>
	</mat-paginator>
</div>