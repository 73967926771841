<div [class.scroll-lock]="enlargedImage">  
	<div class="blue-title-bg">
		Property{{propertyIndex}} : {{showing.property.address.fullAddress}}
	</div>
	<div class="fav-rating-wrapper">
		<div class="fav-wrapper"><b class="fav-heading">Favorite: </b>
			<img [src]="favoriteStatus(showing)" alt="Favorite Icon" width="32">
		</div>
		<div><div [innerHTML]="RatingStatus(showing)"></div></div>
	</div>
	<div>
		<h1 class="heading_2">Property Photos:</h1>
		<app-image-viewer-element class="image-viewer" [images]="getImageSet()"	[thumbnailIndex]="thumbnailImageIndex">
		</app-image-viewer-element>
	<div>

	<div>
		<h1 class="heading_2">Comments:</h1>
		<ul class="visit-comment">
			<li *ngFor="let comment of comments()">{{ comment }}</li>
		</ul>
	</div>

	<div>
		<ng-container *ngIf="showing['clientPhotos'].length > 0">
			<h1 class="heading_2">Your Photos:</h1>
			<div>
				<app-image-row [selected]="areClientPhotosSelected()" [images]="filterImagesByType(showing['clientPhotos'], 'jpg')" (onImageClick)="onSelectClientThumbnail($event)">
				</app-image-row>
			</div>
		</ng-container>
	</div>

	<div>
		<ng-container *ngIf="showing['clientPhotos'].length > 0">
			<h1 class="heading_2">Your Videos:</h1>
			<div>
				<app-image-row [selected]="areClientPhotosSelected()" [images]="filterImagesByType(showing['clientPhotos'], 'mp4')" (onImageClick)="onSelectClientThumbnail($event)"></app-image-row>
			</div>
		</ng-container>
	</div>
	<div *ngIf="enlargedImage" class="enlarged-image">
		<img [src]="enlargedImage" (click)="closeEnlargedImage()" class="enlarged-img">
	</div>

</div>