import { Property } from './propertySearch'
import { Agent, ListAgent } from './agent'

interface ShowingConfirmationRequest {
	id: number;
	createdAt: string;
	updatedAt: string;
	confirmed: boolean;
	confirmationToken: string;
	instructions: string;
	responded: boolean;
	viewed: boolean;
	counteredTime: string;
	showings: Showing;
	listAgent: ListAgent;
	requestActions: RequestActions
}

interface RequestActions {
	id: number;
	name: string;
	code: number;
}

interface Showing {
	id: number;
	showingTime: Date;
	showingOrder: number;
	instructions: string;
	checked: boolean;
	showingConfirmationRequest: [ShowingConfirmationRequest];
	property: Property
	agent: Agent
}
interface ShowingOrderUpdate {
	id: number;
	showingOrder: number;
}

interface ShowingRequestInterface {
	style: any;
	name: string;
	value: number;
}

/**
 The possible static values for a request status and its associated style/name/value
*/
class ShowingRequestStatus {
	static none: ShowingRequestInterface = {
		style: { 
			color: "primary", 
			icon: "error", 
			title: "Request time?"
		},
		name: "None",
		value: 0
	}
	static notime: ShowingRequestInterface = {
		style: { 
			color: "primary", 
			icon: "error", 
			title: "Request time?",
			style: {
				"background-color": "#bebebe",
			}
		},
		name: "None",
		value: 0
	}
	static confirmed: ShowingRequestInterface = {
		style: {
			color: "warn", 
			icon: "thumb_up", 
			title: "Confirmed!",
			style: {
				"background-color": "#75db1a"
			}
		},
		name: "Confirmed",
		value: 1
	}
	static countered: ShowingRequestInterface = {
		style: {
			color: "warn", 
			icon: "update", 
			title: "View counter",
			style: {
				"background-color": "#92d1d4"
			}
		},
		name: "Countered",
		value: 2
	}
	static responded: ShowingRequestInterface = {
		style: {
			color: "link", 
			icon: "thumb_down", 
			title: "View response",
			style: {
				"color": "#e84928" 
			}
		},
		name: "Responded",
		value: 3
	}
	static viewed: ShowingRequestInterface = {
		style: {
			color: "accent", 
			icon: "pending", 
			title: "Request sent!"
		},
		name: "Viewed",
		value: 4
	}
}

export { Showing, ShowingOrderUpdate, ShowingConfirmationRequest, ShowingRequestStatus, ShowingRequestInterface, RequestActions }