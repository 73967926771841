import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-list',
  templateUrl: './confirm-list.component.html',
  styleUrls: ['./confirm-list.component.css']
})
export class ConfirmListComponent {

	list: string[] = []
	message: string = "Are you sure?"
	confirmButtonText = "YES"
	cancelButtonText = "CANCEL"

	/**
 	 The text of an extra button if provided
	*/
	extraButtonText = null

	/**
 	 The action of an extra button if provided
	*/
	extraButtonAction = () => {}

	/**
 	 Selected list item
	*/
	selectionChoice: string = null

	/**
 	 Can the main affirmative button be used or not
	*/
	positiveButtonDisabled() {

		if ( this.list.length > 1 && this.selectionChoice == null ) {
			return true
		}

		return false
	}

	constructor( 
		@Inject(MAT_DIALOG_DATA) private data: any,
		private dialogRef: MatDialogRef<ConfirmListComponent>) {
    
		if (data) {
		    this.message = data.message ?? this.message;
		    this.list = data.list ?? this.list
		    this.extraButtonAction = data.extraAction 
		    
			if (data.buttonText) {
				this.confirmButtonText = data.buttonText.ok ?? this.confirmButtonText;
				this.cancelButtonText = data.buttonText.cancel ?? this.cancelButtonText;
				this.extraButtonText = data.buttonText.extra ?? this.extraButtonText
			}
		}
	}

	/**
 	 If an extra button is provided, it should have an associated action.
	*/
  	onExtraButton() {
  		if ( this.extraButtonAction != null ) {
  			this.extraButtonAction()
  		}
  	}

  	/**
 	 Action for affirmative confirmation
	*/
	onConfirmClick(selection): void {
  	
		// Auto select if there is only one selection available
		if ( this.list.length == 1 ) {
			selection.selectedOptions.select(selection.options.first)
		}

		this.dialogRef.close( { confirmed: true, selection: selection.selectedOptions.selected[0]?.value });
	}

}


