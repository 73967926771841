import { Injectable } from '@angular/core';
import { HttpClient,  HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class MapsService {

	private devApiKey: string = environment.maps.google.apiKey
	private readonly MAPBOX_ACCESS_TOKEN = environment.map.mapbox.apiKey
	apiKey: string = this.devApiKey

	constructor(private http: HttpClient) {
	}

	geocode( address ) {

		address = encodeURIComponent(address.replace(/(\s+)/ig, " "))

	    var geocode = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${this.apiKey}`;

	    return this.http.get(geocode);
	}

	/**
 	 Send an address string to Google maps api for requesting the api.
	*/
	// autocomplete( address, cb ) {

	// 	// address = encodeURIComponent(address)

    //     // function logPlaceDetails() {
    //     //   var service = new google.maps.places.PlacesService(document.getElementById('map'));
    //     //   service.getDetails({
    //     //     placeId: 'ChIJN1t_tDeuEmsRUsoyG83frY4'
    //     //   }, function (place, status) {
    //     //   });
    //     // }

    //     let request = { input: address }

	// 	let autocomplete = new google.maps.places.AutocompleteService()
	// 	autocomplete.getPlacePredictions(request, cb)

	// }

	autocomplete(address: string, cb: Function) {
		// const apiUrl = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
		//   address
		// )}.json?access_token=${this.MAPBOX_ACCESS_TOKEN}&country=us`;
		const apiUrl = `https://api.mapbox.com/autofill/v1/suggest/${encodeURIComponent(address)}?types=address&streets=true&access_token=${this.MAPBOX_ACCESS_TOKEN}&country=us&proximity=ip`;
	
		this.http.get(apiUrl).subscribe((response: any) => {
		  cb(null, response.suggestions);
		}, (error: any) => {
		  cb(error);
		});
	}

	autocompleteGeo(address: string, cb: Function) {
		const apiUrl = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
		  address
		)}.json?access_token=${this.MAPBOX_ACCESS_TOKEN}&country=us`;
	
		this.http.get(apiUrl).subscribe((response: any) => {
		  cb(null, response.features);
		}, (error: any) => {
		  cb(error);
		});
	}

}
