import { Component } from '@angular/core';
import { bathroomCalculation, checkForAuthError} from '../utils'
import { ToursService } from '@app/services/tours.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoginService } from '@app/services/login.service';
import { ConfirmListComponent } from '@app/confirm-list/confirm-list.component';
import { MatDialog } from '@angular/material/dialog';
import { ErrorModalComponent } from '@app/error-modal/error-modal.component';
import { ConfirmInputComponent } from '@app/confirm-input/confirm-input.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-wish-list',
  templateUrl: './wish-list.component.html',
  styleUrls: ['./wish-list.component.css']
})
export class WishListComponent {
  
  wishList:any[]=[]
  error:any=null;
  showDone: boolean;
  isDataLoaded:boolean = false;
  constructor(private tourService:ToursService,
    protected dialog: MatDialog,
    private modalService: NgbModal,
    private loginService:LoginService,protected router: Router, 
    private snackBar: MatSnackBar,
    ){
  }
  bathRoomCalculation(property) {
	return bathroomCalculation(property?.BathroomsTotalInteger);
	// const bathroomsTotal = property?.BathroomsTotalInteger;
	// if(bathroomsTotal === null|| bathroomsTotal === 'null'){
	// 	return 'N/A';
	// }
	// if (typeof bathroomsTotal === 'undefined') {
	//   return 'NA';
	// } else if (bathroomsTotal === 0) {
	//   return 'NA';
	// } else {
	//   return `${bathroomsTotal}`;
	// }
  }
   ngOnInit(){
    this.getWishList();
  }
  handleImageError(tile: any) {
	tile.url = "./assets/no-property-available.png"; // Set the URL of the fallback image
  }
  getWishList(){
    const user = this.loginService.getUser();
    this.tourService.getUserWishList(user.id).subscribe({
        next:(res)=>{
            if ( res["errors"] != undefined ) {
 				this.error = checkForAuthError( res["errors"], this.router )
   				return
   			} 
            console.log("object-rser",res['data']['getPropertyWishList'])
            this.wishList =res['data']['getPropertyWishList'];
			this.isDataLoaded = true;
        },
        error:(err)=>{
            console.log("object-error",err);
        }
    })
  }
  removeWishlist(item){
    console.log("object",item);
    this.tourService.removeWishList(item.id).subscribe({
      next:(res)=>{
        if ( res["errors"] != undefined ) {
					this.error = checkForAuthError( res["errors"], this.router )
	  				return
	  			} 
          this.snackBar.open(res['data']['deletePropertyWish'].message , 'Close', {
            duration: 5000, // notification will automatically close after 5 seconds
            horizontalPosition: 'center', // position the notification at the right corner of the screen
            verticalPosition: 'top', // position the notification at the top of the screen
          });
          this.getWishList();
      },error:(err)=>{
        this.error = err
      }
    })
  }
    /**
 	 Allow the user to choose his tour 
	*/
  showSpinner: boolean = false;
	onAddToTour(tourAdditions) {
		// let map = this.route.snapshot.queryParams

		this.showSpinner = true;

	
		// Get the agent's tours
		this.tourService.fetchTours().subscribe((tours) => {
		  // Assume for alpha stage that an error means logged out
		  if (tours['errors'] != null) {
			this.error = tours['errors'][0].message;
			return;
		  }
	
		  if (tours['data']['tours'].length === 0) {
			this.showCreateTourPopup(tourAdditions);
			return;
		  }

		  const filteredTours = tours['data']['tours'].filter((tour) => tour.tourCompleted === 0);
	
		  // Have him choose which
		  const dialogRef = this.dialog.open(ConfirmListComponent, {
			data: {
			  message: 'Please select a tour',
			  buttonText: {
				ok: 'SELECT',
				cancel: 'CANCEL',
				extra: 'CREATE NEW',
			  },
			//   list: tours['data']['tours'].map((x) => x.name),
			list: filteredTours.map((x) => x.name),
			  extraAction: () => {
				// An extra close call is probably not needed...
				dialogRef.close();
				this.showCreateTourPopup(tourAdditions);
			   },
			},
		  });
	
		  // Tour select action
		  dialogRef.afterClosed().subscribe((result) => {
			this.error = null;
		  
			if (result?.confirmed) {
			  let tourId = tours['data']['tours'].filter((x) => {
				return x.name == result.selection;
			  })[0]?.id;
			  let propertyIdList = tourAdditions.id;
		  
			  this.tourService.fetchTourSummary(tourId).subscribe((response) => {
				const tourSummary = response['data']; // The tour summary data is now directly available in response.data
				// Process the tour summary here
				const showings = tourSummary['tours'][0]['showings'];
				const existingPropertyIds = showings.map((showing) => {
				  return showing['property']['id'];
				});
				const existingPropertyIdsStrings = existingPropertyIds.map(String); // Convert numbers to strings
		  
				const newPropertyIdList = [];
				const existingPropertyIdList = [];
				const existingPropertyAddressList = [];
				const newPropertyAddressList = [];
		  
				  const showing = showings.find((showing) => showing['property']['id'] === propertyIdList);
				  if (existingPropertyIdsStrings.includes(propertyIdList) && showing ) {
					const address = showing['property']['propertyFeatures']['featureData']['UnparsedAddress'];
					existingPropertyIdList.push(propertyIdList);
					existingPropertyAddressList.push(address);
				  } else {
					newPropertyIdList.push(propertyIdList);
				  }
	
				if (newPropertyIdList.length === 0) {
				  const errorMessage = ['Selected properties already exist.'];
				  this.openErrorModal(errorMessage, false);
				  this.showSpinner = false;
				  return;
				}
	
				if (newPropertyIdList.length !== 0 && existingPropertyIdList.length !== 0) {
				  const errorMessage = existingPropertyAddressList;
				  this.openErrorModal(errorMessage, true);
				  this.showSpinner = false;
				  return;
				}
		  
				this.tourService.addPropertiesToTour(newPropertyIdList, tourId).subscribe((showingTour) => {
				  this.showSpinner = false;
				  // Assume for alpha stage that an error means logged out
				  if (showingTour['errors'] != null) {
					this.error = showingTour['errors'][0].message;
					return;
				  }
				  this.navigateToTours();
				});
			  });
			} else {
			  this.showSpinner = false;
			}
		  });
		});
	}
  openErrorModal(errorMessage: string[], isExistingPropertyError: boolean) {
		const modalRef = this.modalService.open(ErrorModalComponent);
		modalRef.componentInstance.errorMessage = errorMessage;
		modalRef.componentInstance.isExistingPropertyError = isExistingPropertyError;
	  }
  showCreateTourPopup(tourAdditions) {
		const inputDialogRef = this.dialog.open(ConfirmInputComponent, {
		  data: {
			message: 'Please name your new tour',
			buttonText: {
			  ok: 'CREATE',
			  cancel: 'CANCEL',
			},
			inputs: [
			  { name: 'Tour Name', value: null },
			  { name: 'Tour Description', value: null },
			],
		  },
		});
	  
		let runAfterClosed = (result) => {
		  if (result?.confirmed) {
			this.showSpinner = true;
	  
			let propertyIdList =
			  tourAdditions != null ? tourAdditions.id : null;

			// Create the new tour and get its id
			this.tourService.addTour(result.tour, propertyIdList, null).subscribe((response) => {
			  // Rest of your existing code for creating the new tour...
			  if(response['errors'] != null) {
				this.error = response['errors'][0].message;
				return;
			  }
			  this.navigateToTours();
	  		});
	  
			this.showSpinner = false;
			this.showCheckmark(null);
		  }
		  else{
			this.showSpinner = false;
		  }
		};
	  
		runAfterClosed.bind(this);
	  
		inputDialogRef.afterClosed().subscribe(runAfterClosed);
	  }
    navigateToTours() {
      this.router.navigate(['/tours']);
      }
      showCheckmark(next) {
        this.showDone = true;
        setTimeout(() => {
          this.showDone = false;
      
          if (next != null) {
          // Pass "self" context
          next(this);
          }
        }, 3000);
        }
}
