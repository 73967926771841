import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { BigAssButtonComponent } from '../big-ass-button/big-ass-button.component'

@Component({
  selector: 'app-header-action-bar',
  templateUrl: './header-action-bar.component.html',
  styleUrls: ['./header-action-bar.component.css']
})
export class HeaderActionBarComponent implements OnInit {

	@Input()
	error: string = null

	@Input()
	showSmallButtons: boolean

	@Input()
	firstSmallIconName: string

	@Input()
	secondSmallIconName: string

	@Input()
	title: string

	@Input()
	subtitle: string

	@Input()
	header: string

	@Input()
	firstButtonTitle

	@Input()
	secondButtonTitle

	@Output()
	firstButtonAction: EventEmitter<string> = new EventEmitter<string>()

	@Output()
	secondButtonAction: EventEmitter<string> = new EventEmitter<string>()

	buttonStyle = {
		"min-height": "500px" 
	}

	// logo = "./assets/livepad-logo-black.png"
	logo = "./assets/edit-icon-white.png"

	logoStyle = { 
		"height" : "200px",
		"opacity": "0.2",
	}

	constructor() { }

	ngOnInit(): void {


	}

	onFirstButtonAction() {

		this.firstButtonAction.emit("first button")
	}

	onSecondButtonAction() {

		this.secondButtonAction.emit("second button")
	}

}
