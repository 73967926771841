<div class="header-wrapper">
	<div [ngClass]="{'headImage': !isConfirmation, 'headImageNew': isConfirmation || forgetPassword}">
		<div>
			<p><button class="transparent-btn-bg" *ngIf="loginService.authorization?.token != null && !isDashboardPage && !isConfirmation && !galleryPage && !forgetPassword" tabindex="0" (click)="handleBackButtonClick()">
				<img src="assets/back-btn.svg" alt="Back Button" width="20px">
			</button></p>
			<p>
				<button *ngIf="!isConfirmation && !forgetPassword" (click)="!isConfirmation&&!passwordchange&&!galleryPage ? navigateToDashboard() : null" class="header-logo-bg" tabindex="0" title="Livepad Logo">
					<img src="assets/livepad-logo-white.png" alt="Livepad Logo" height="60px">
				</button>
				<button *ngIf="isConfirmation || forgetPassword" class="header-logo-new" tabindex="0" title="Livepad Logo">
					<img src="assets/livepad-text-new.svg" alt="Livepad Logo" height="40px">
				</button>
		</div>
			<div *ngIf="!isConfirmation && !forgetPassword" class="avatar-align-right">
			<span class="login-title" tabindex="0">{{buttonTitle()}}</span>
			<!-- <img class="agent-thumbnail" width="45px" *ngIf="user?.name != null" src="{{user?.agent?.picture}}" onerror="this.src='./assets/avatar.jpg'" (click)="routeToAccount()" alt="Agent Picture" tabindex="0" /> -->
			<!-- <img class="agent-thumbnail" width="45px" *ngIf="loginService.authorization?.token != null" src="{{user?.agent?.picture}}" onerror="this.src='./assets/avatar.jpg'" (click)="!isConfirmation&&!passwordchange ? showAgentOptions() : null"
			alt="Agent Picture" tabindex="0"/> -->
			<img class="agent-thumbnail" width="45px" *ngIf="loginService.authorization?.token != null" [src]="isValidImageUrl(user?.agent?.picture) ? user?.agent?.picture : './assets/avatar.jpg'" onerror="this.src='./assets/avatar.jpg'" (click)="!isConfirmation&&!passwordchange ? showAgentOptions() : null"
			alt="Agent Picture" tabindex="0"/>
			<ng-container *ngIf="showAgentOptionsFlag">
				<div class="agent-options">
				  <p (click)="routeToAccount()">Profile</p>
				  <p (click)="changePassword()">Change Password</p>
				  <p (click)="routeToWishList()">Wishlist</p>
				</div>
			</ng-container>			
			<app-big-ass-button *ngIf="!isConfirmation" [buttonImage]="logo" [buttonStyle]="buttonStyle" (click)="!passwordchange ? handleButtonText() : null" tabindex="0"></app-big-ass-button>
			</div>
    </div>
	<span class="signout_text" *ngIf="showSignOutText" (click)="handleButtonClick()" tabindex="0" title="SignOut">Sign Out <i class="bi bi-chevron-right"></i></span>
</div>