import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';

import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

import { Showing, RequestActions } from '../showing'
import { Tour } from '../tour'
import { Property } from '../propertySearch'
import { Client } from '../client'
import { ImageViewerComponent, ImageViewerData } from '../image-viewer/image-viewer.component'
import { bathroomCalculation, addCommasToPrice, firstOf } from '../utils'
import { AgentCardComponent } from '../agent-card/agent-card.component'

export enum ImageSet {
  listingPhotos,
  clientPhotos
}

@Component({
  selector: 'app-tour-summary-details-card',
  templateUrl: './tour-summary-details-card.component.html',
  styleUrls: ['./tour-summary-details-card.component.css']
})
export class TourSummaryDetailsCardComponent implements OnInit {

  @Input()
  property: Property

  @Input()
  client: Client

  @Input()
  showing: Showing

  @Input() propertyIndex: number;

  enlargedImage: string | null = null;

  thumbnailImageIndex: number

  imageSet = ImageSet.listingPhotos

  constructor() { }

  ngOnInit(): void {
  }


  /**
   Client comments
  */
  comments () {

    if (this.showing['clientComments']?.length < 1) {
      return ["No comments"]
    }

    return this.showing['clientComments']?.map( each => { return each.comments })
  }

  filterImagesByType(images: any[], type: string): any[] {
    return images.filter(image => image.type === type);
  }

  hasComments() {
    return this.showing['clientComments'] && this.showing['clientComments']?.length > 0;
  }

  hasVideos(): boolean {
    if (this.showing['clientPhotos']) {
      for (const image of this.showing['clientPhotos']) {
        if (image.endsWith('.mp4')) {
          return true;
        }
      }
    }
    return false;
  }

  getPropertyListingPhotosData(event) {
    return {
      images: this.showing.property.propertyPhotos,
      activeImage: 0
    }
  }

  onSelectThumbnail(newIndex) {
    this.imageSet = ImageSet.listingPhotos
    this.thumbnailImageIndex = newIndex
  }

  // onSelectClientThumbnail(newIndex) {
  //   this.imageSet = ImageSet.clientPhotos
  //   this.thumbnailImageIndex = newIndex
  // }
  onSelectClientThumbnail(image: string): void {
    this.enlargedImage = image;
  }

  closeEnlargedImage(): void {
    this.enlargedImage = null;
  }

  getImageSet() {
    switch(this.imageSet) {
      case ImageSet.listingPhotos:
        return this.showing.property.propertyPhotos
      case ImageSet.clientPhotos:

        return this.showing.property.clientPhotos
    }    
  }

  areListingPhotosSelected() {
    return this.imageSet == ImageSet.listingPhotos ? true : null
  }

  areClientPhotosSelected() {
    return this.imageSet == ImageSet.clientPhotos ? true : null
  }
  

  listPrice() {
    return `$${addCommasToPrice(this.showing.property.propertyFeatures['featureData'].ListPrice)}`
  }

  numBedrooms() {
    return this.showing.property?.propertyFeatures['featureData'].BedroomsTotal || 0 
  }

  numBathrooms() {
    return bathroomCalculation(this.showing.property)
  }

  squareFeet() {
    return this.showing.property?.propertyFeatures['featureData'].LivingArea
  }

  lotSize() {
    return this.showing.property?.propertyFeatures['featureData'].LotSizeAcres
  }

  favoriteStatus(showing: any): string {
    if (showing?.clientFavorites[0]?.favoriteStatus == 1) {
      return "./assets/favorite.jpg";
    }
    
    return "./assets/non_favorite.jpg";
  }

  RatingStatus(showing: any): string {
    const rating = showing?.propertyRatings?.[0]?.starRating;
  
    if (rating && rating >= 1 && rating <= 5) {
      const filledStars = rating;
      const emptyStars = 5 - rating;
      
      const filledStarHtml = '<img src="./assets/rating.jpg" alt="Filled Star" width="32">&nbsp'.repeat(filledStars);
      const emptyStarHtml = '<img src="./assets/non_rating.jpg" alt="Empty Star" width="32">&nbsp'.repeat(emptyStars);
      
      return filledStarHtml + emptyStarHtml;
    }
    
    const emptyStarHtml = '<img src="./assets/non_rating.jpg" alt="Empty Star" width="32">&nbsp;'.repeat(5);
    return emptyStarHtml;
  }

}
