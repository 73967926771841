// auth.guard.ts
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { LoginService } from './login.service';
import { LocalStorage } from '../localStorage'

@Injectable({
  providedIn: 'root'
})
export class LoginAuthGuard implements CanActivate {

  constructor( private router: Router) {}

  canActivate(): boolean {
    if (Date.now()<LocalStorage.get('expired')) {
      // User is already authenticated, prevent access to the login page
      this.router.navigate(['/dashboard']); // Redirect to another route
      return false;
    }
    return true; // Allow access to the login page
  }
}