import { Injectable, OnInit } from '@angular/core';
import { Observable, forkJoin, of, throwError } from 'rxjs';
import { catchError, map, retry, switchMap } from 'rxjs/operators';

// import { User } from '../user'
import { HttpClient,  HttpErrorResponse } from '@angular/common/http';
import { Authorization } from '../authorization'
import { PropertySearch, PropertyFilter, Property } from '../propertySearch'
import { LoginService } from './login.service'
import { BasicObject } from '../basicObject'
import { environment } from '../../environments/environment'
// import { graphQlFormat } from '../graphQlFormatting'
import { ServiceErrors } from './service-errors'
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class PropertyService {

	private graphApi:string = environment.graphApi;

	authorization: Authorization

	searchResults: [Property]
	searchCriteria: [PropertySearch]


	constructor( private loginService:LoginService, private http:HttpClient, private router: Router ) { 
		this.checkAuth()
	}

	checkAuth () {
		this.authorization = this.loginService.getAuthorization()
		if ( !this.authorization?.token ) { 
			const currentUrl = this.router.url;
			this.loginService.setTargetUrl(currentUrl);
			console.error("No auth token present") 
		}
	}

	runPropertySearch ( matching: BasicObject[], fields: string[], mlsId ) {

		this.checkAuth()

	  	var addressFields = ["fullAddress",
			"streetNumber",
			"streetName",
			"unitNumber",
			"zip",
			"crossStreet",
			"city",
			"county",
			"state",
			"country"]

		let addressJoins = `{ ${addressFields.join(" ")} }`

		let reducedMatching = "[ "

		// matching.forEach(x => {		
		// 	reducedMatching += `{ name: "${x.name}", value : "${x.value}" },` 
		// })

		let mlsStatusValues = [];
		matching.forEach(x => {
			if (x.name === "mlsStatus") {
			  // If the name is mlsStatus, add the values to mlsStatusValues array
			  mlsStatusValues = mlsStatusValues.concat(x.value);
			} else {
			  // For other names, add to reducedMatching as before
			  reducedMatching += `{ name: "${x.name}", value : "${x.value}" },`;
			}
		});
		
		reducedMatching = reducedMatching.slice(0,reducedMatching.length-1) + " ]"

		// let query = `query { properties( matching: ${reducedMatching}, fields: ${JSON.stringify(fields)}, mlsId: "${mlsId}" ) { id listingId listingKey dataSource originatingMls mlsStatus propertyFeatures address ${addressJoins} } }`
		// let query = `query { properties( matching: ${reducedMatching}, fields: ${JSON.stringify(fields)}, mlsId: [${mlsId}] ) { id listingId listingKey dataSource originatingMls mlsStatus LivingArea BedroomsTotal BathroomsTotalInteger ListPrice UnparsedAddress YearBuilt View LotSizeAcres DaysOnMarket ListAgentFullName ListOfficeName } }`
		let query = `query { properties( matching: ${reducedMatching}, fields: ${JSON.stringify(fields)}, mlsId: [${mlsId}], mlsStatus :${JSON.stringify(mlsStatusValues)} ) { id listingId listingKey dataSource originatingMls mlsStatus LivingArea BedroomsTotal BathroomsTotalInteger ListPrice UnparsedAddress YearBuilt View LotSizeAcres DaysOnMarket ListAgentFullName ListOfficeName } }`

	  	let body = { "query" : query } 
	  	let options = { headers : { "Authorization" : `Bearer ${this.authorization.token}`} }
	  	return this.http.post(this.graphApi, body, options )
	  		.pipe(
	  			catchError(ServiceErrors.handleError)
	  		)

	}

	/**
 	 Use to get a list of properties by their MLS ids ( ListingId ) & their mls name ( i.e. INCLINE, etc )
	*/
	fetchPropertiesForMls( listingIds: [String], mls: String ) {

		this.checkAuth()

	  	var addressFields = ["fullAddress",
			"streetNumber",
			"streetName",
			"unitNumber",
			"zip",
			"crossStreet",
			"city",
			"county",
			"state",
			"country"]
		let addressJoins = `{ ${addressFields.join(" ")} }`
		let photoFields = ["url", "id", "type", "displayOrder"]
		let photoJoins = `{ ${photoFields.join(" ")} }`

		let query = `query { propertiesFromMls( listingIds: ${ JSON.stringify(listingIds) }, originatingMls: "${mls}" ) { id listingId listingKey dataSource originatingMls propertyFeatures address ${addressJoins} propertyPhotos ${photoJoins} } }`
	  	let body = { "query" : query } 
	  	let options = { headers : { "Authorization" : `Bearer ${this.authorization.token}`} }
	  	return this.http.post(this.graphApi, body, options )
	  		.pipe(
	  			catchError(ServiceErrors.handleError)
	  		)

	}

	/**
 	 Just fetch a single property 
	*/
	fetchPropertyByListingKey( listingKey: String, clientId: String ) {

		this.checkAuth()

	  	var addressFields = ["fullAddress",
			"streetNumber",
			"streetName",
			"unitNumber",
			"zip",
			"crossStreet",
			"city",
			"county",
			"state",
			"country"]
		let addressJoins = `{ ${addressFields.join(" ")} }`
		let photoFields = ["url", "id", "type", "displayOrder", "mediaKey", 'longDescription', 'isTour', 'category']
		let photoJoins = `{ ${photoFields.join(" ")} }`

		// Pass along a client id to include that client's fav data/comments/photos of this property
		let clientJoins = ''
		let clientInfo = ''

		if ( clientId != null ) {

			let client = 'clients { id firstName lastName }'
			clientInfo = `clientId: "${clientId}" `

			let clientRatingFields = ` propertyRatings { starRating } `
			let clientPhotos = ` clientPhotos { url type } `
			let clientFavorites = `clientFavorites { favoriteStatus }`
			let clientComments = `clientComments { id comments createdAt ${client} }`
			clientJoins = `${clientFavorites} ${clientComments} ${clientRatingFields} ${clientPhotos}`
		}


		let query = `query { property( listingKey: "${listingKey}" ${clientInfo} ) { id listingId listingKey dataSource originatingMls propertyFeatures address ${addressJoins} propertyPhotos ${photoJoins} ${clientJoins} } }`
	  	let body = { "query" : query } 
	  	let options = { headers : { "Authorization" : `Bearer ${this.authorization.token}`} }
	  	return this.http.post(this.graphApi, body, options )
	  		.pipe(
	  			catchError(ServiceErrors.handleError)
	  		)
	}
	/***
	 * fetch the data using forkjoin
	 */
	getSingleWishList(listingKey,userId):Observable<any>{
		this.checkAuth()
		let query = `query{	getPropertyWishList(userId:${userId},listing_key:"${listingKey}"){id,userId,	listing_id,	listing_key,propertyId,	property{id,UnparsedAddress,ListPrice, LotSizeAcres }}}`;
	  	let body = { "query" : query } 
		let options = { headers : { "Authorization" : `Bearer ${this.authorization.token}`} }
	  	return this.http.post(this.graphApi, body, options )
	  		.pipe(
	  			catchError(ServiceErrors.handleError)
	  		)
	}
	fetchPropertyData(listingKey,clientId,userId): Observable<any> {
		// Use forkJoin to make parallel API calls
		return forkJoin([
			this.fetchPropertyByListingKey( listingKey, clientId ),
			this.getSingleWishList(listingKey,userId).pipe(
			  catchError(() => {
				// Handle error if needed (e.g., data not found in the second API)
				return [];
			  })
			),
		  ])
		  .pipe(
			switchMap(([api1Data, api2Data]) => {
			  // Check if the data from the first API exists in the data from the second API
			  const wishlistFlag = api2Data['data']['getPropertyWishList'].filter((item) => {
				return item.propertyId == api1Data['data']['property'].id
			});
				if(wishlistFlag.length==1){
				const flag:boolean= wishlistFlag[0]['property']?.id == api1Data['data']['property']?.id;
				api1Data['data']['property']['wishlistFlag']=flag;
				api1Data['data']['property']['wishlistId']=wishlistFlag[0]['id'];
				}else{
					api1Data['data']['property']['wishlistFlag']=false;
				}
			  // Modify the data from the first API based on the flag
			  const modifiedApi1Data = [{ ...api1Data }];
			  return modifiedApi1Data;
			})
		  );
	}

	getPropertStatusList():Observable<any>{
		this.checkAuth()
		let query = `query{ getPropertStatusList{ mlsStatus }}`;
	  	let body = { "query" : query } 
		let options = { headers : { "Authorization" : `Bearer ${this.authorization.token}`} }
	  	return this.http.post(this.graphApi, body, options )
	  		.pipe(
	  			catchError(ServiceErrors.handleError)
	  		)
	}
}
