<!-- <carousel class="mt-2">
    <slide *ngFor="let tile of tiles | keyvalue">
      <img *ngIf="isImage(tile.value) == true" [src]="tile.value.url" alt="{{ tile.value.longDescription }}" (click)="imageClick(tile)" (error)="handleImageError(tile)" alt="Images"/>
      <video *ngIf="isImage(tile.value) == false" (click)="imageClick(tile)">
        <source [src]="tile.value.url" type="video/{{ tile.value.type }}">
      </video>
    </slide>
</carousel> -->

<div class="gallery-container">
  <div class="thumbnails-wrapper">
    <div class="slider__prev d-none d-md-flex">
      <button (click)="scrollUp()" *ngIf="tiles.length>1" style="border: none; background: transparent;">
        <div class="arrow-up"><b class="adafix">.</b></div>
      </button>
   </div>
    <div class="thumbnails-container">
      <div class="thumbnail-group">
        <div
          *ngFor="let tile of tiles; let i = index"
          class="thumbnail"
          (click)="selectImage(i)"
          [class.selected]="i === selectedImageIndex"
        >
          <img [src]="tile.url" alt="{{ tile.longDescription }}" class="thumbnail-image" (error)="handleImageError(tile)">
        </div>
      </div>
    </div>
    <div class="slider__next d-none d-md-flex">
      <button (click)="scrollDown()" *ngIf="tiles.length>1" style="border: none; background: transparent;">
        <div class="arrow-down"><b class="adafix">.</b></div>
      </button>
    </div>
  </div>
  <div class="main-image">
    <div class="gallery-controls" [ngClass]="styleClass!=''?styleClass:'gallery-controls'">
      <button class="prev-button" *ngIf="tiles.length>1" (click)="prevImage()">
        <i class="bi bi-chevron-left"><b class="adafix">.</b></i>
      </button>      
      <img
        [src]="tiles[selectedImageIndex]?.url"
        [alt]="tiles[selectedImageIndex]?.longDescription"
        *ngIf="selectedImageIndex !== null"
        class="main-image"
        (error)="handleImageError(tile)"
      >
      <button class="next-button" *ngIf="tiles.length>1" (click)="nextImage()">
        <i class="bi bi-chevron-right"><b class="adafix">.</b></i>
      </button>    
    </div>
  </div>
</div>