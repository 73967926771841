import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { PropertiesComponent } from './properties/properties.component';
import { ClientComponent } from './client/client.component';
import { ToursComponent } from './tours/tours.component';
import { PublicLandingComponent } from './public-landing/public-landing.component';
import { NewClientFormComponent } from './new-client-form/new-client-form.component';
import { NewTourFormComponent } from './new-tour-form/new-tour-form.component';
import { PropertyDetailsComponent } from './property-details/property-details.component';
import { RequestConfirmationComponent } from './request-confirmation/request-confirmation.component';
import { RequestConfirmationListComponent } from './request-confirmation-list/request-confirmation-list.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AgentProfileComponent } from './agent-profile/agent-profile.component';
import { TourSummaryComponent } from './tour-summary/tour-summary.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DataVisualizationComponent } from './data-visualization/data-visualization.component';
import { PropertyNewComponent } from './property-new/property-new.component';
import { ViewClientListNewComponent } from './view-client-list-new/view-client-list-new.component';
import { TourHistoryNewComponent } from './tour-history-new/tour-history-new.component';
import { SearchPropertyNewComponent } from './search-property-new/search-property-new.component';
import { MembershipNewComponent } from './membership-new/membership-new.component';
import { SaveMembershipProfileComponent } from './save-membership-profile/save-membership-profile.component';
import { RegisterComponent } from './register/register.component';
import { PhotoGalleryComponent } from './photo-gallery/photo-gallery.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { AdminPanelComponent } from './admin-panel/admin-panel.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import {  AuthGuard } from './services/auth.guard';
import { AdminAuthGuard } from './services/admin-auth.guard';
import { SuperAdminAuthGuard } from './services/super-admin-auth.guard';
import { WishListComponent } from './wish-list/wish-list.component';
import { LoginAuthGuard } from './services/login-auth.guard';


// TODO: 404 page, lock routes down
const routes: Routes = [
  {
    path: '',
    component: PublicLandingComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [LoginAuthGuard]
  },
  {
    path: 'register',
    component: RegisterComponent,
  },
  {
    path: 'properties',
    component: PropertiesComponent,
    canActivate:[AuthGuard]
  },
  {
    path: 'clients',
    component: ClientComponent,
    canActivate:[AuthGuard]
  },
  {
    path: 'tours',
    component: ToursComponent,
    canActivate:[AuthGuard]
  },
  {
    path: 'new-client',
    component: NewClientFormComponent,
    // canActivate:[AuthGuard]
  },
  {
    path: 'new-tour',
    component: NewTourFormComponent,
    // canActivate:[AuthGuard]
  },
  {
    path: 'change-password',
    component: ChangePasswordComponent,
  },
  {
    path: 'property/:listingKey',
    component: PropertyDetailsComponent,
    // canActivate:[AuthGuard]
  },
  {
    path: 'property/:listingKey/client/:clientId',
    component: PropertyDetailsComponent,
    // canActivate:[AuthGuard]
  },
  {
    path: 'client/:clientId',
    component: NewClientFormComponent,
    // canActivate:[AuthGuard]
  },
  {
    path: 'confirmation-request/:confirmationToken',
    component: RequestConfirmationComponent,
    // canActivate:[AuthGuard]
  },
  {
    path: 'showing/requests/:showingId',
    component: RequestConfirmationListComponent,
    // canActivate:[AuthGuard]
  },
  {
    path: 'agent/:agentLinkName',
    component: AgentProfileComponent,
    // canActivate:[AuthGuard]
  },
  {
    path: 'tour-summary/:tourId',
    component: TourSummaryComponent,
    // canActivate:[AuthGuard]
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate:[AuthGuard]
  },
  {
    path: 'reports',
    component: DataVisualizationComponent,
    // canActivate:[AuthGuard]
  },

  {
    path: 'new-prop',
    component: PropertyNewComponent,
    // canActivate:[AuthGuard]
  },
  {
    path: 'view-clients',
    component: ViewClientListNewComponent,
    // canActivate:[AuthGuard]
  },
  {
    path: 'tour-history',
    component: TourHistoryNewComponent,
    // canActivate:[AuthGuard]
  },

  {
    path: 'search-prop',
    component: SearchPropertyNewComponent,
    // canActivate:[AuthGuard]
  },
  {
    path: 'member-licence',
    component: MembershipNewComponent,
    // canActivate:[AuthGuard]
  },

  {
    path: 'saved-profile',
    component: SaveMembershipProfileComponent,
    // canActivate:[AuthGuard]
  },
  {
    path: 'gallery',
    component: PhotoGalleryComponent,
    // canActivate:[AuthGuard]
  },
  {
    path: 'wishlist',
    component: WishListComponent,
    canActivate:[AuthGuard]
  },
  {
    path: 'admin',
    loadChildren: () =>
    import('@module/admin-panel/admin-panel.module').then((m) => m.AdminPanelModule),
    canLoad:[AdminAuthGuard],
    data:{}
  },
  {
    path: 'client-complete',
    loadChildren: () =>
    import('@module/client-mobile-panel/client-mobile-panel.module').then((m) => m.ClientMobilePanelModule),
    // canLoad:[AdminAuthGuard],
    data:{}
  },
  {
    path: 'my-document',
    loadChildren: () =>
    import('@module/my-document-panel/my-document-panel.module').then((m) => m.MyDocumentModule),
    canLoad:[AuthGuard],
    data:{title:'Documents'}
  },
  {
    path: 'forgot-password/:token',
    component: ForgotPasswordComponent,
  },
  {
    path: 'page-not-found',
    component: PageNotFoundComponent,
  },
  // This one always at the bottom of the list
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
