import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../services/login.service';
import { User } from '../user';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
  /**
   Error if create client or other server request failed
  */
  error: string = null;

  user: User;
  isAdmin: boolean = false;
  isSuperAdmin: boolean = false;

  constructor(private router: Router,
    public loginService: LoginService) {
      this.user = this.loginService.user;

      this.loginService.userChange.subscribe((user) => {
        this.user = user;
      });
    }
  
    ngOnInit(): void {
      console.log("user role is",this.user.orguserdetails[0])
      if (this.user.orguserdetails[0]?.roleId == 1) {
        this.isSuperAdmin = true;
      } else if (this.user.orguserdetails[0]?.roleId == 2) {
        this.isAdmin = true;
      }
    }

  gotoMyDoc(){
    this.router.navigate(['/my-document']);
  }

  gotoTour() {
    // this.router.navigate(['/new-tour']);
    this.router.navigate(['/tours']);
  }

  gotoClient() {
    // this.router.navigate(['/clients']);
    this.router.navigate(['/clients']);
  }

  gotoProperties() {
    // this.router.navigate(['/new-prop']);
    this.router.navigate(['/properties']);
  }

   adminpage() {
    this.router.navigate(['/admin']);
  }
  
}
