<div class="content-container">
	<div class="title">
		<h1 class="floatText"><img src=" assets/clients-icon-list-new.svg" alt="Client Icon" class="clients-icon-list" tabindex="0"> <span tabindex="0">{{title}}</span></h1>

		<div class="small-buttons top-alignment" *ngIf="showSmallButtons">
			<a href="javascript:void(0)" class="editprofile" *ngIf="firstSmallIconName" (click)="onFirstButtonAction()" tabindex="0"
				style="position: relative;">{{firstSmallIconName}} <i class="bi bi-plus-square plusicon"
					style="color:#0C4675;padding-left: 2px;"></i>
			</a>
			<a href="javascript:void(0)" class="editprofile" *ngIf="secondSmallIconName" (click)="onSecondButtonAction()" tabindex="0"
				style="position: relative;">{{secondSmallIconName}}<i class="bi bi-plus-square plusicon"
					style="color:#0C4675;padding-left: 2px;"></i>
			</a>
		</div>
	</div>

	<div *ngIf="!showSmallButtons" class="sub-title">
		<div class="tourContent" align="center">
		  <h1 tabindex="0" class="mobile-alignment">{{subtitle}}</h1>
		  <div class="textBlue">
			<a (click)="onFirstButtonAction()">{{firstSmallIconName}} <i class="bi bi-plus-square plusicon" ></i></a>
		  </div>
		</div>
	</div>

	<div class="footer">
		<span class="left-align" *ngIf="error">{{error}}</span>
	</div>
</div>
