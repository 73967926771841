import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { User } from '../user';
import { LoginService } from '../services/login.service';
import { BigAssButtonComponent } from '../big-ass-button/big-ass-button.component';
import { Router, ActivatedRoute, ParamMap, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-header-nav-bar',
  templateUrl: './header-nav-bar.component.html',
  styleUrls: ['./header-nav-bar.component.css'],
})
export class HeaderNavBarComponent implements OnInit {
  @Input()
  drawer: any;

  user: User;

  isDashboardPage = false;

  showAgentOptionsFlag = false;

  logo = 'assets/login-icon.svg';

  showSignOutText = false;
  isConfirmation = false;
  passwordchange = false;
  galleryPage = false;
  forgetPassword = false;

  buttonStyle = {
    "height": "44px",
		"width": "50px",
  };



  constructor(
    public loginService: LoginService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private elRef: ElementRef
  ) {
    // Set initial
    this.user = this.loginService.user;

    // Watch for changes
    this.loginService.userChange.subscribe((user) => {
      this.user = user;
    });
  }

  ngOnInit(): void {
    document.body.addEventListener('click', this.handleDocumentClick);
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.closeAgentOptions();
        const url = event.url;
        this.isDashboardPage = url === '/dashboard';
        this.isConfirmation = url.includes('/confirmation-request');
        this.passwordchange = url.includes('/forget-password');
        this.galleryPage = url.includes('/gallery');
        this.forgetPassword = url.includes('/forget-password');
      }
    });
  }

  handleBackButtonClick() {
    this.location.back();
  }

  showAgentOptions() {
    this.showAgentOptionsFlag = !this.showAgentOptionsFlag;
    if (this.showAgentOptionsFlag) {
    this.showSignOutText = false;
    }
  }

  handleDocumentClick = (event: MouseEvent) => {
    // Check if the click target is outside of the agent options
    if (!this.elRef.nativeElement.contains(event.target)) {
      // If outside, close the agent options
      this.closeAgentOptions();
    }
  };

  closeAgentOptions() {
    this.showAgentOptionsFlag = false;
    this.showSignOutText = false;
  }

  routeToAccount() {
    // if (this.user?.name == null) {
    if (this.loginService.authorization?.token == null) {
      this.router.navigate(['/login'], {
        queryParams: this.route.snapshot.queryParams,
      });
      return;
    }

    this.router.navigate([`/agent/${this.user.agent.link}`], {
      queryParams: this.route.snapshot.queryParams,
    });
    this.showAgentOptionsFlag = false;
  }
  routeToWishList() {
    if (this.loginService.authorization?.token == null) {
      this.router.navigate(['/login'], {
        queryParams: this.route.snapshot.queryParams,
      });
      return;
    }

    this.router.navigate([`/wishlist`], {
      queryParams: this.route.snapshot.queryParams,
    });
    this.showAgentOptionsFlag = false;
  }

  buttonTitle() {
    // if (this.user?.name == null) {
    if (this.loginService.authorization?.token == null) {
      return 'Log In';
    } else {
      return `Hello ${this.user.name}!`;
    }
  }

  handleButtonText() {
    if (this.loginService.authorization?.token == null) {
      this.router.navigate(['/login'], { queryParams: this.route.snapshot.queryParams })
      return;
    }
    this.showSignOutText = !this.showSignOutText; // toggle showSignOutText
    if (this.showSignOutText) {
      this.showAgentOptionsFlag = false; // Hide the agent options when showing Sign Out text
    }
}

  handleButtonClick() {
    if (this.showSignOutText) {
      this.handleSignOutClick();
      return ;
    }
    this.showSignOutText = true;
  }

  handleSignOutClick() {
    this.onSignOut();
    this.showSignOutText = false;
  }

  navigateToDashboard() {
    if (this.loginService.authorization?.token != null) {
      this.router.navigateByUrl('/dashboard');
    }
    else {
      this.router.navigate(['login'])
    }
  }

  changePassword() {
    this.router.navigate(['/change-password']);
    this.showAgentOptionsFlag = false; // Hide the agent options after selecting an option
  }

  onSignOut() {
		this.loginService.logout()
		this.router.navigate(['/login'], { queryParams: this.route.snapshot.queryParams })
    this.showSignOutText = false;
	}

  isValidImageUrl(url: string): boolean {
    const regex = /^(https:\/\/d3o40wwl2vh905\.cloudfront\.net\/assets\/images\/avatar\/agents\/\d+_\d+\.(jpg|png|jpeg))|(blob:.+)$/i;
    return regex.test(url);
  }
}
