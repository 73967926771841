<app-header-action-bar title="My Tours"	[showSmallButtons]="tours.length > 0" firstSmallIconName="Add Tour"	subtitle="Create, manage and schedule tour times here!"	firstButtonTitle="Create Tour" (firstButtonAction)="onAddTour()" [error]="error">
</app-header-action-bar>

<app-tour-list 
	*ngIf="tours.length > 0 && showToursList" 
	[tours]="tours" 
	[requestActions]="requestActions"
	(onDelete)="onDeleteTours($event)"
	(onDeleteTour)="onDeleteTour($event)"
	(onDeleteShowing)="onDeleteShowing($event)"
	(onRequestConfirmation)="onRequestConfirmation($event)"
	(onSetTourDate)="onUpdateTourDate($event)"
	(onSetShowingSchedule)="onUpdateShowingTime($event)"
	(onRemoveClient)="onRemoveClient($event)"
	(updateRequest)="onUpdateRequest()">
</app-tour-list>

<app-property-list 
	*ngIf="showTrailingProperties()"
	[properties]="trailingProperties"
	[showCheckbox]="false"
	[showAddToTours]="false">	
</app-property-list>

<app-spinner [show]="showSpinner"></app-spinner>
<app-checkmark *ngIf="showDone"></app-checkmark>
