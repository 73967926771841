import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, CanLoad, Router, RouterStateSnapshot,Route, UrlSegment, UrlTree  } from '@angular/router';
import { Observable, of } from 'rxjs';
import { LoginService } from './login.service';
import { LocalStorage } from '@module/localStorage';
import { Role } from '../user';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    public loginService: LoginService,
    public router: Router,
    private route: ActivatedRoute // private toastrServe:ToastrService,
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if (this.loginService.isUserRole) {
        if(this.loginService.isUserRole == Role.Agent){
          return of(true);
        }
        return of(true);
      } else {
        LocalStorage.clear();
        this.router.navigate(['/login']);
        return of(false);
      }
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if (this.loginService.authorization && this.loginService.isUserRole) {
          return of(true);
        } else {
        LocalStorage.clear();
        this.router.navigate(['/login']);
        return of(false);
      }
  }
}
