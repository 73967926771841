<div class="footerwrapper">
	<div class="">
		<div class="whiteLogo">
			<span class="footerContent" tabindex="0">Live Solutions, LLC &#64; 2024</span>
			<img src="assets/footer-logo.svg" alt="Livepad Logo" tabindex="0" width="138" class="footer-logo">
		</div>
		<div class="footerImage">
			
		</div>
	</div>
</div>

