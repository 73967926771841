<div class="error-modal">
<div class="modal-header">
    <h1 class="modal-title">Error</h1>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h2>
      {{ isExistingPropertyError ? 'Below are the existing properties:' : 'Selected properties already exist.' }}
    </h2>
    <ul>
        <ng-container *ngIf="isExistingPropertyError">
          <li *ngFor="let message of errorMessage" class="error">{{ message }}</li>
        </ng-container>
    </ul>
    <h3>Please add only new properties.</h3>
  </div>
</div>
  
  