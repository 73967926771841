<div style="position: relative;">
	<div class="welcome" tabindex="0">
		<label class="horizontalline"><b class="adafix">.</b></label>
		{{"REGISTER"}}
		<label class="horizontalline"><b class="adafix">.</b></label>
	</div>
	<div *ngIf="isAgent" class="form-signup-Image">
	<div class="triangle-signup-bg">
		<img src="assets/livepad-logo-black.svg" alt="Livepad Logo" class="livpadLogo1" tabindex="0">
	</div>	
	<div class="signup-container">
		<div class="row handleForm">
			<h1 class="signup-title" tabindex="0">Sign Up</h1>
			<hr>
			<div class="row">
				<div class="col-md-4 Colpad">
					<div class="form-group groupMargin"  style="text-align: left;">
						<label class="nameLabel" tabindex="0">Username <span style="color: red">*</span></label>
						<input type="text" [formControl]="nameControl" id="userName" aria-labelledby="userName" class="mt-1 inputTag form-control"
						style="background:transparent;" tabindex="0" Username autocomplete="off" (keyup.enter)="onEnter()"/>
						<div class="error-message" *ngIf="nameControl.invalid">A user name with no symbols is required</div>
					</div>
				</div>
				<div class="col-md-4 Colpad">
					<div class="form-group groupMargin"  style="text-align: left;">
						<label class="nameLabel" tabindex="0">First Name <span style="color: red">*</span></label>
						<input type="text" [formControl]="firstNameControl" id="firstName" aria-labelledby="firstName" class=" mt-1 inputTag form-control" tabindex="0"	style="background:transparent;" FirstName autocomplete="off" (keyup.enter)="onEnter()"/>
						<div class="error-message" *ngIf="firstNameControl.invalid">First name with no symbols, digits and space is required</div>	
					</div>
				</div>
				<div class="col-md-4">
					<div class="form-group groupMargin"  style="text-align: left;">
						<label class="nameLabel" tabindex="0">Last Name <span style="color: red">*</span></label>
						<input type="text" [formControl]="lastNameControl" id="lastName" aria-labelledby="lastName" class="mt-1 inputTag form-control"
								style="background:transparent;" tabindex="0" LastName autocomplete="off" (keyup.enter)="onEnter()"/>
						<div class="error-message" *ngIf="lastNameControl.invalid">Last name with no symbols, digits and space is required</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-md-4 Colpad">
					<label class="mt-3 nameLabel" tabindex="0">Phone <span style="color: red">*</span></label>
					<input #input type="tel" [formControl]="phoneControl" tabindex="0" id="phone" aria-labelledby="phone" class="mt-3 inputTag form-control"
							style="background:transparent;" autocomplete="off" NumberOnly (keyup.enter)="onEnter()"  (keydown.Tab)="onKey($event);"/>
					<div class="error-message" *ngIf="phoneControl.invalid">Valid Phone Number is required</div>
				</div>
				<div class="col-md-4 Colpad">
					<label #email class="mt-3 nameLabel" tabindex="0">Email <span style="color: red">*</span></label>
					<input type="text" [formControl]="emailControl" tabindex="0" autocomplete="off" class="mt-3 inputTag form-control" id="signin-email" aria-labelledby="signin-email" style="background:transparent ;" (keyup.enter)="onEnter()"/>
					<div class="error-message" *ngIf="emailControl.invalid">Valid Email is required</div>
				</div>
			</div>

			<div class="row mt-5" style="text-align: left;">
				<div class="col-md-6 Colpad">
					<label #yourMls class="nameLabel" tabindex="0">Select Your Organization <span style="color: red">*</span></label>
					<mat-select class="mt-3 inputTag form-control" tabindex="0" id="yourMls" aria-labelledby="yourMls" [formControl]="orgControl"
						style="background:transparent;" (keyup.enter)="onEnter()">
						<mat-option *ngFor="let org of orgList" [value]="org" autocomplete="off" class="mt-3 inputTag form-control"
							style="background:transparent;">
							{{org.name}}
						</mat-option>
					</mat-select>
					<div class="error-message" *ngIf="orgControl.invalid">Organization is required</div>
				</div>
			</div>

			<!-- <div style="text-align: center; font-size: 20px; margin-top: 2%; font-family: 'Dunbar Tall', sans-serif;">
				<span style="color: black;">
					If you cannot find your organization in the above list, please 
					<span (click)="orgControl.value ? ($event.preventDefault() && $event.stopPropagation()) : onLinkClick()" [style.color]="orgControl.value ? 'grey' : '#0C4675'" style="cursor: pointer; font-weight: bold;">
						click here
					</span>	
				</span>
			</div> -->

			<div class="row signBtn"  style="text-align: center;">
				<div class="col-md-6">
					<button type="submit" class="btn btnColor form-control" (click)="onRegister()" tabindex="0"> REQUEST SIGN UP
					</button>
				</div>
				<div class="col-md-6">
					<button type="submit" class="btn  signup form-control" (click)="cancelRegistration()" tabindex="0">
						CANCEL
					</button>
				</div>
			</div>
		</div>
	</div>
	<div class="error-message" *ngIf="error != null">{{error}}</div>
</div>
</div>
<app-spinner [show]="showSpinner"></app-spinner>
<app-checkmark *ngIf="showDone"></app-checkmark>