import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.css']
})
export class SearchBarComponent implements OnInit {

	@Output()
	onFilterChange: EventEmitter<string> = new EventEmitter<string>()
	inputValue: string;
	constructor() { }

	ngOnInit(): void {
	}

	applyFilter(event) {
		this.inputValue = event.target.value;
		this.onFilterChange.emit(this.inputValue)
	}
	resetInput() {
		this.inputValue = '';
	}
}
