<div style="position: relative;">
	<div class="welcome" tabindex="0">
		<label class="horizontalline"><b class="adafix">.</b></label>
		{{"WELCOME"}}
		<label class="horizontalline"><b class="adafix">.</b></label>
	</div>
	<div align="center" *ngIf="!showRegistration">
		<div class="formImage">
			<div class="triangle-bg">
				<img src="assets/livepad-logo-black.svg" alt="Livepad Logo" class="livpadLogo" tabindex="0">
				<div class="empty-space"></div>
			</div>		
			<div class="iconLive">	
				<div class="form">
					<div class="col-md-10 resForm">
						<div class="form-group" align="left">
							<label class="mt-3 labelName" tabindex="0">Email <span style="color: red">*</span></label>
							<input type="text" [formControl]="emailControl" id="loginEmail" aria-labelledby="loginEmail" class="mt-3 inputLine form-control"
								style="background:transparent;" tabindex="0" (keyup.enter)="onEnter()"/>
							<!-- <div class="error-message" *ngIf="emailControl.invalid">Please enter a valid email</div> -->
							<!-- <div class="error-message">{{ emailControl.invalid ? 'Please enter a valid email' : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' }}</div> -->
							<label class="labelName passwordLabel" tabindex="0">Password <span style="color: red">*</span></label>
							<div class="position-relative">
								<input [type]="fieldTextType ? 'text' : 'password'" [formControl]="passwordControl" id="loginPassword" aria-labelledby="loginPassword" class="mt-3 inputLine form-control"
								style="background:transparent ;" tabindex="0" (keyup.enter)="onEnter()"/>
								<span (click)="toggleFieldTextType()" class="position-absolute" style="top: 50%;right: 1px;transform: translate(-50%, -50%);cursor: pointer;" *ngIf="(passwordControl.value)">
									<img src="{{fieldTextType?'assets/eye-off-password.svg':'assets/eye-password.svg'}}" width="16" alt="view icon"/>
								</span>
							</div>
							<!-- <div class="error-message" *ngIf="passwordControl.invalid">Please enter a valid password</div> -->
							<!-- <div class="error-message">{{ passwordControl.invalid ? 'Please enter a valid password' : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' }}</div> -->
							<!-- <div>
								<div class="error-message" *ngIf="error != null">{{error}}</div>
							</div> -->
							<div class="error-message" *ngIf="emailControl.invalid || passwordControl.invalid || error">
								<ng-container *ngIf="emailControl.invalid && passwordControl.invalid">
									Please enter valid email and password
								</ng-container>
								<ng-container *ngIf="emailControl.invalid && !passwordControl.invalid">
									Valid email required
								</ng-container>
								<ng-container *ngIf="!emailControl.invalid && passwordControl.invalid">
									Valid password required
								</ng-container>
								<ng-container *ngIf="error && !emailControl.invalid && !passwordControl.invalid">
									{{ error }}
								</ng-container>
							</div>
							<div class="error-message" *ngIf="!(emailControl.invalid || passwordControl.invalid || error)">
								&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
							</div>
							<div style="position: relative;">
								<div class="btnsClass">
									<button type="submit" class="btn btnColor form-control" (click)="onLogin()" tabindex="0">
										LOG IN </button>
								</div>
								<span class="forgot-password-link" (click)="openForgotPasswordModal()" (keyup.enter)="openForgotPasswordModal()" tabindex="0">Forgot Password?</span>
								<div class="login-btns-container btnsClass">
									<span class="btn-text form-control newUser" tabindex="0">
										New Member?
									</span>
									<button type="submit" class="btn signup form-control"
											(click)="beginRegistration()" tabindex="0">
											SIGN UP
									</button>									
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<ng-template #passwordPolicyDialog style="width: 50%;">
	<div>
	  <h2>We've updated our password policy.</h2>
	  <p>Please change your password accordingly to ensure compliance. Thank you.</p>
	  <button mat-button class="highlight-on-click"  (click)="closePasswordPolicyDialog()">Okay</button>	
	</div>
</ng-template>
<app-spinner [show]="showSpinner"></app-spinner>
<app-checkmark *ngIf="showDone"></app-checkmark>