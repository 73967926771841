import { Component, OnInit } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ToursService } from './services/tours.service'
import { Actions } from './actions'
import { environment } from '@env/environment';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';


@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

	title = 'LivePad';

	sideNavMode = "over"
	hideWebView:boolean=true;
	constructor( private toursService: ToursService,private router: Router ) {
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
    			const url = event.url;
			  this.hideWebView = !url.includes('/client-complete');
			}
		  });
		if (environment.devProd) {
			const state1 = localStorage.getItem('logState');
			if (!state1 || state1 !== 'enabled') {
			  console.log = () => {};
			  console.warn = () => {};
			}
		  }
	}

	ngOnInit() {

		this.router.events
        .pipe(filter((event) => event instanceof NavigationEnd))
        .subscribe((event: any) => {
          if(event.url.includes('my-document')){
			// const getPathParams=localStorage.getItem('panel_path');
			// if(getPathParams){
            //     this.router.navigate(['/my-document',getPathParams])
            // }else{
            //     this.router.navigate(['/my-document'])
            // }
			this.router.navigate(['/my-document'])
			localStorage.setItem('panel_path', '');
          }
	})
		this.fetchRequestActions()
		// this.emptyButton(){
		// 	('.carousel-indicators button').html('<b></b>')
		// }
	}

	/**
 	 Fetch all the Request Actions available
	*/	
	fetchRequestActions() {

		this.toursService
			.fetchRequestActions()
			.subscribe(response => { 
		
					// Assume for alpha stage that an error means logged out
					if ( response["errors"] != null ) {
						// console.error(response["errors"] )
						return
					}  


					// Dynamically create typed members
					response["data"].requestActions
						.forEach( x => {
							let key = x.name.replace(/\s/g, '')
							Actions[key] = x
						})

		})
	}
}


// emptyButton(){
//     setTimeout(() => {
//       var spans = document.querySelectorAll('.carousel-indicators button');
//       if(this.isValueEmpty(spans)){
//         if(spans.length > 0){   
//           spans[0].innerHTML="nbsp;nbsp;";     
//         }
//       }
//     },3000);
//   }  


