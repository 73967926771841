<div style="position: relative;" class="mb-4">
    <div class="client" style="position: relative;">
        <img src=" assets/home-icon.svg" alt="Image" class="homelog" style="position: relative;">
        <span class="floatText" style="position: relative;">Michael Matten</span>
        <span class="editprofile" style="position: relative;" (click)="gotoForm()">EDIT <i class="bi bi-pencil-square"
                style="color:#0C4675;" (click)="gotoForm()"></i></span>
        <span class="line"></span>
    </div>

    <div class="clientForm">
        <div class="row" style="position: relative; ">

            <div class="col-md-2">
                <div class="imagePlacing" align="center">
                    <img src="assets/girl-with-livepad-on-couch.jpg" class="prfileImage" alt=" Image Missing">
                </div>
                <p class="mt-2 image">Change Image <i class="bi bi-pencil-square"></i></p>
            </div>


            <div class="col-md-10 formText " style="margin-left: -80px; ">
                <div class=" row " style=" float: left;">
                    <div class="col-md-4" align="right">
                        <div>Phone</div>
                        <div class="mt-3">Emial</div>
                        <div class="mt-3">Address</div>
                    </div>

                    <div class="col-md-8 ">
                        <div>(555)555-5555</div>
                        <div class="mt-3">mmattesn.com</div>
                        <div class="mt-3">
                            <p>555 Test Drive Lane </p>Live Citye, 5555
                        </div>
                    </div>
                </div>

                <div class="row" style="display: inline;">
                    <span class="touring" style="position: relative;">Memberships
                        <i class="bi bi-plus-square plusicon"></i></span>
                    <span class="lineProp" style="margin-left:220px;padding-top: 90px;"></span>

                    <div class="row location" style="padding-top: 30px;">
                        <div style="margin-bottom: 20px;">
                            <img src="assets/locate-icon.svg" alt="Image" width="2%" style="opacity: 1;">
                            <span class="verticalLine"></span>

                            <img src="assets/locate-icon.svg" alt="Image" width="2%"
                                style="opacity: 1;margin-left: 20px;">
                            <span class="verticalLine"></span>
                        </div>

                        <div>
                            <span>Incline Village Realtors</span>
                            <span class="verticalLine"></span>
                            <span style="opacity: 1;margin-left: 20px;">Vigilante Realtors</span>
                            <span class="verticalLine"></span>
                            <span style="opacity: 1;margin-left: 20px;">FSBO Realtors</span>
                            <span class="verticalLine"></span>
                        </div>
                    </div>



                    <div class="row" style="position: relative;">
                        <span class="touring" style="position: relative;">License
                            <i class="bi bi-plus-square plusicon"></i></span>
                        <span class="lineProp"></span>
                    </div>

                    <div class="row location">
                        <div style="margin-bottom: 20px;">
                            <span>#125478M23456</span>
                            <span class="verticalLine"></span>

                            <span style="opacity: 1;margin-left: 20px;">State of MIchigan</span>
                            <span class="verticalLine"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>