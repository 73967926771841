import { Component, OnInit, Output, Input, EventEmitter, ElementRef, SimpleChange } from '@angular/core';import { MatGridListModule } from '@angular/material/grid-list';
import { Tile } from '../tile'
import { PropertyPhoto } from '../propertyPhoto'
import { isImage } from '../utils'
import { interval, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-image-grid',
  templateUrl: './image-grid.component.html',
  styleUrls: ['./image-grid.component.css']
})


export class ImageGridComponent implements OnInit {

	@Input()
	images: PropertyPhoto[]
	@Input()
	styleClass: string ='';

	@Input()
	selectedImage: number

	@Output()
	onImageClick: EventEmitter<Tile> = new EventEmitter<Tile>()

	tiles: Tile[] = []


	// Scope access
	isImage = isImage

	selectedImageIndex: number = 0;

	private destroy$ = new Subject<void>();

	_selectedI: number

	@Input()
	set selectedI(val: number) {
	  this._selectedI = val;
	}
	get selectedI() {
		return this._selectedI
	}

	constructor(private elementRef: ElementRef) { 
	}

	ngOnInit(): void {

		this.imagesToTiles()

	// 	interval(4000) 
    //   .pipe(takeUntil(this.destroy$))
    //   .subscribe(() => this.nextImage());

	}

	ngOnChanges(changes:{[selectedI:number]: SimpleChange}) {
		console.log("changes",changes,this.selectedI);
		if(this.selectedI != undefined && this.selectedI != null){
			this.selectedImageIndex = this.selectedI;
			this.scrollThumbnailToSelected(this.selectedI);
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	// handleImageError(tile: any) {
	// 	tile.value.url = "./assets/no-property-available.png"; // Set the URL of the fallback image
	// }
	handleImageError(tile: any) {
		tile.url = "./assets/no-property-available.png"; // Set the URL of the fallback image
	}

	imageClick(tile) {
		this.onImageClick?.emit(tile)
	}

	/**
 	 Sort images array ascending by tour
	*/
	sortByTour(arr) {

		return arr.sort( (lhs, rhs) => {

			if ( lhs.isTour == true && !rhs.isTour ) {
				return -1
			}
			
			if ( rhs.isTour == true && !lhs.isTour ) {
				return 1
			}

			return 0
			
		})

	}

	selectImage(index: number) {
		this.selectedImageIndex = index;
		this.scrollThumbnailToSelected(index); 
	}

	scrollThumbnailToSelected(index: number) {
		  // Get the selected thumbnail element
		  const selectedThumbnail = this.elementRef.nativeElement.querySelector('.thumbnail.selected');
	  
		  // Calculate the scroll position to make the selected thumbnail scroll to the top
		  if (selectedThumbnail) {
			const scrollContainer = this.elementRef.nativeElement.querySelector('.thumbnails-container');
			const offsetTop = index * (selectedThumbnail.offsetHeight + 10);
	  
			// Scroll to the selected thumbnail with smooth behavior
			scrollContainer.scrollTo({ top: offsetTop, behavior: 'smooth' });
		  }
	}
	  

	nextImage() {
		this.selectedImageIndex = (this.selectedImageIndex + 1) % this.images.length;
		this.scrollThumbnailToSelected(this.selectedImageIndex); 
	}

	prevImage() {
		this.selectedImageIndex = (this.selectedImageIndex - 1 + this.images.length) % this.images.length;
		this.scrollThumbnailToSelected(this.selectedImageIndex);
	}

	ScrollIntoView(elem: string) {
        document.querySelector(elem).scrollIntoView({ behavior: 'smooth', inline: 'nearest'});
    }


	/**
 	 Convert the image objects into a tile-compatible format
	*/
	imagesToTiles() {

		if ( this.images?.length < 1 ) {
			this.selectedImageIndex = 0;

			let imageNotExist = { url : './assets/no-property-available.png', rows:1, cols:1,
			mediaKey: '.png',
			displayOrder: 1,
			type: 'image',
			longDescription: 'image not exist',
			category: 'carousel'
		}

			this.tiles.push(imageNotExist) 
			return
		}
		this.selectedImageIndex = 0;
		// Other tiles will randomize 1x1, 1x2, 2x1 or 2x2
		for (var i in this.images ) {

			let image = this.images[i]
			let randCol = 1
			let randRow = 2

			let ind = Number(i)

			// Top tile is large always
			if ( ind == 0 ) {
				randCol = 2
				randRow = 3

			} 

			let tile: Tile = <Tile>image

			tile.cols = randCol 
			tile.rows = randRow
				
			this.tiles.push(tile)
		}
	}


	getRandomInt(max) {
	  return Math.floor(Math.random() * Math.floor(max))
	}

	scrollUp() {
		if (this.selectedImageIndex > 0) {
		  this.selectedImageIndex--;
		  this.scrollThumbnailToSelected(this.selectedImageIndex);
		}
	}
	  
	scrollDown() {
		if (this.selectedImageIndex < this.images.length - 1) {
		  this.selectedImageIndex++;
		  this.scrollThumbnailToSelected(this.selectedImageIndex);
		}
	}

	currentSlide(n) {

		console.log("n is",n);
		this.selectedImageIndex = (this.selectedImageIndex - 1 + this.images.length) % this.images.length;
		console.log("this.selectedImageIndex",this.selectedImageIndex);
		this.scrollThumbnailToSelected(this.selectedImage);
	}
}
