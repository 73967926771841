<div class="content-container">
  <div class="title">
    <h1 tabindex="0"><img src=" assets/Properties.svg" alt="Properties Icon" width="70px"> Properties</h1>
  </div>
  <div class="row propertiesFields">
    <div class="col-md-12"><button class="btn btn-text btnAgent" tabindex="0">Search</button></div>
    <div class="col-md-4 Colpad" *ngFor="let field of searchFields; let index = index;">
      <label #input2 class="mt-3 labelName" tabIndex="0" [attr.for]="'search-field-' + index">{{field.name}}</label>
        <!-- <mat-select *ngIf="field.isDropdown" class="mt-3 inputLine form-control" (selectionChange)="onUpdateSearchFieldValue($event, field.selection, field)" 
        [(value)]="field.selection.selectedField.value" [attr.id]="'search-field-' + index" [attr.aria-label]="field.name.replace(' ', '-') + 'search field'" 
        placeholder="{{field.placeholder}}" style="background: transparent; opacity: 0.7;">
          <mat-form-field>
            <input matInput [(ngModel)]="field.selection.searchText" (ngModelChange)="filterSearchFieldValuesList($event, field.selection)" name="search" placeholder="Search">
          </mat-form-field>
          <mat-option *ngFor="let fieldValue of field.selection.filteredValues" [value]="fieldValue">
            {{fieldValue.display}}
          </mat-option>
        </mat-select> -->
        <mat-select [disableOptionCentering]="true" *ngIf="field.isDropdown" class="mt-3 inputLine form-control" (selectionChange)="onUpdateSearchFieldValue($event, field.selection, field)" 
        [(value)]="field.selection.selectedField.value" [attr.id]="'search-field-' + index" [attr.aria-label]="field.name.replace(' ', '-') + 'search field'" 
        placeholder="{{field.placeholder}}" style="background: transparent; opacity: 0.7;" [multiple]="field.searchKey=='mlsStatus'?true:false"  [panelClass]="field.searchKey=='mlsStatus'?'test2Class':'testClass'">
          <div class="input-group col">
            <input class="form-control search-input-field" [(ngModel)]="field.selection.searchText" (ngModelChange)="filterSearchFieldValuesList($event, field.selection)" name="search" placeholder="Search">
          </div>
          <mat-option *ngIf="field.selection.selectedField.searchKey == 'mlsStatus'" [value]="'Active'">
            Active
          </mat-option>
          <mat-option *ngFor="let fieldValue of field.selection.filteredValues" [value]="fieldValue">
            {{fieldValue.display}}
          </mat-option>
        </mat-select>
       <input type="text" class="mt-3 inputLine form-control" *ngIf="!field.isDropdown" placeholder="{{field.placeholder}}" 
        [(ngModel)]="field.value" autocomplete="off" name="search value" (keydown)="onKeyDown($event, field, index)" (keyup.enter)="onSearch()" [attr.id]="'search-field-' + index" [attr.aria-label]="field.name.replace(' ', '-') + ' search field'" style="background:transparent; opacity: 0.7;" (keyup.enter)="onEnter()">
    </div>
    <div class="button-group mt-3 mobile-margin">
      <button class="big-ass-button" (click)="onSearch()" tabindex="0">SEARCH</button>
      <button class="cancel-button" (click)="onClearFilters()" tabindex="0">CLEAR FILTERS</button>
    </div>
  </div>
  <app-property-list *ngIf="properties.length > 0" [properties]="properties" (onAppendToTour)="onAddToTour($event)" (onConfirmTour)="confirmTour($event)"></app-property-list>
  <app-no-results *ngIf="properties.length < 1 && didSearch == true"></app-no-results>
  <app-spinner [show]="showSpinner"></app-spinner>
  <app-checkmark *ngIf="showDone"></app-checkmark>
</div>
