
<div class="row" align="center">
	<p class="createTour" tabindex="0">{{message}}</p>
	<div class="form-group" *ngFor="let input of inputs" align="left">
		<label class="mt-3 label" for="{{ input.name }}" tabindex="0">{{ input.name }}</label>
		<input type="text" name="input" id="{{ input.name }}" aria-labelledby="{{ input.name }}" [(ngModel)]="input.value" (keyup.enter)="onEnter()" class="inputLine form-control" style="background:transparent ;" tabindex="0" maxlength="250"/>
	</div>
</div>
<mat-dialog-actions class="btn-container">
	<button mat-raised-button [disabled]="positiveButtonDisabled()" (click)="onConfirmClick()" tabindex="0" class="btn btnColor form-control">{{confirmButtonText}}</button>
	<button mat-raised-button mat-dialog-close tabindex="0" class="btn signup form-control">{{cancelButtonText}}</button>
</mat-dialog-actions>

