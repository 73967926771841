import { AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';

export class PasswordValidator {

    public static strong(control: AbstractControl): ValidationErrors | null { 

        let hasNumber = /\d+/.test(control.value);
        let hasUpper = /[A-Z]+/.test(control.value);
        let hasLower = /[a-z]+/.test(control.value);
        let hasSymbol = /\W+/.test(control.value);
        let length = control.value?.length >= 6 && control.value?.length <= 14

        // 'Num, Upp, Low', hasNumber, hasUpper, hasLower
        const valid = hasNumber && hasUpper && hasLower && hasSymbol && length;
        if (!valid) {
            // return what´s not valid
            return { strong: true };
        }
        return null;
    }
    public static validate(control: AbstractControl): ValidationErrors | null { 
      const password: string = control.value;
      const passwordRegex: RegExp = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/;
      if (!password) {
        return { 'required': true }; // Return a validation error if the password is empty
      }
      let hasNumber = /\d+/.test(password);
      let hasUpper = /[A-Z]+/.test(password);
      let hasLower = /[a-z]+/.test(password);
      let hasSymbol = /\W+/.test(password);
      //let hasLen = password?.length >= 8 && password?.length < 14;
      if (!passwordRegex.test(password)) {
        return { invalidPassword: true ,hasNumber,hasUpper,hasLower,hasSymbol}; // Return a validation error if the password doesn't meet criteria
      }
      return null; 
    }   
}
export const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    return control.value.password1 === control.value.password2
      ? null
      : { PasswordNoMatch: true };
};